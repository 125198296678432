import React, { useState, useEffect, forwardRef } from 'react';
import moment from "moment";
import { OrdersGanttView } from "./OrdersGanttView";
import { DeliveryGanttView } from "./DeliveryGanttView";
import Constants from "../../../utils/constants";
import { SelectAlphaIcon } from "../../input/SelectAlphaIcon";
import SVGraphics from "../../../assets/SVGraphics";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { GanttNotes } from "./gantComponenets/GanttNotes";
import { authenticationService } from "../../../services/AuthinticationService";
import SystemAdmin from "../../../services/ApiServices/SystemAdminApi";
import GanttApi from "../../../services/ApiServices/GanttApi";
import utils from "../../../utils/utils";
import OrdersApi from "../../../services/ApiServices/OrdersApi";
import { ChambersApi } from '../../../services/ApiService';
import { EditOrderPlan } from "../orders/EditOrderPlan";


export function GanttNew(props) {
    const [startDate, setStartDate] = useState(new Date()); // Initial start date
    const [endDate, setEndDate] = useState(new Date()); // Initial end date
    const [currentRange, setCurrentRange] = useState([]);
    const [currentMonth, setCurrentMonth] = useState("");
    const [currentOrder, setCurrentOrder] = useState({});
    const [currentPlan, setCurrentPlan] = useState({});
    const [isEditOrder, setIsEditOrder] = useState(false);
    const [isGanttNote, setIsGanttNote] = useState(false);
    const [currentUser, setCurrentUser] = useState({});
    const [ganttFilter, setGanttFilter] = useState({ filters: [] });
    const [ganttOrders, setGanttOrders] = useState([]);
    // const [ordersNum, setOrdersNum] = useState([]);
    const [originOrders, setOriginOrders] = useState([]);
    const [siteID, setSiteID] = useState(props && props.currentSite && props.currentSite.value);
    const [siteName, setSiteName] = useState("");
    // const [chamberName, setChamberName] = useState("");
    const chambersColors = ['#EBFBFF', '#DBECE9'];

    const [searchInputs, setSearchInputs] = useState(authenticationService.currentUserValue?.role[0] !== "SystemAdmin" ? Constants.gantt_searchInputs : Constants.gantt_searchInputsSystem);


    const fetchBlockData = async (chamberID) => {
        let data = await ChambersApi.getChamberCalendarActivity(chamberID)
        if (data && data.data) {
            data.data?.blockedChambers?.forEach(d => {
                d['type'] = 'block'
                d['start'] = new Date(d.startDate)
                d['end'] = new Date(d.endDate)
            })
            data.data.SubPlans.forEach(d => {
                d['type'] = 'order'
                d['start'] = new Date(d.startDate)
                d['end'] = new Date(d.endDate)
            })
            let calendarData = data.data.blockedChambers.concat(data.data.SubPlans)
        }

    }
    useEffect(() => {
        let user = authenticationService.currentUserValue
        setCurrentUser(user)
        getRange(new Date(new Date().setDate(new Date().getDate() + 4)))
        setEndDate(new Date(new Date().setDate(new Date().getDate() + 4)))
        if (user?.role[0] === "SystemAdmin" || props.currentSite) {
            updateSearchInput(user).then()
        }
    }, [])
    useEffect(() => {
        if (props.currentSite) {
            async function refreshPage() {
                console.log("UPD! " + props.currentSite.value)
                await setGanttFilter({ filters: [] })
                await setSiteID(props.currentSite.value)
                await getGanttData(props.currentSite.value, '', '', '', true).then()
            }
            refreshPage()
        }
    }, [props.currentSite])
    const updateSearchInput = async (user) => {
        let _searchInputs = [...searchInputs]
        if (user?.role[0] === "SystemAdmin") {
            let _sites = await getSites()
            if (_sites) {
                _searchInputs[0].data = _sites
                if (_sites.length > 0) {
                    let defaultSite = _sites.find(x => x.IsDefault === true);
                    filterOnChangeProg({
                        label: defaultSite ? defaultSite.label : _sites[0].label,
                        value: defaultSite ? defaultSite.value : _sites[0].value
                    }, 'SiteID')
                }
            }
        } else {
            setSiteID(user?.profile?.SiteID)
            getGanttData(user?.profile?.SiteID, '').then()
        }
        await setSearchInputs([..._searchInputs])
    }

    const getSites = async () => {
        return await fetchData()
    }

    const fetchData = async () => {
        let _sites = await SystemAdmin.getAllSitesAdvanced({ filters: [] })
        let options = []
        if (_sites && _sites.data) {
            await _sites.data.forEach(s => {
                options.push({ value: s.SiteID, label: s.SiteName, IsDefault: s.IsDefault })
            })
        }
        return options
    }
    const planSaved = () => {
        getGanttData(siteID, 'SiteID', startDate, endDate).then()
        setIsEditOrder(false)
    }
    const getGanttData = async (siteId, type, start, end, refresh) => {

        // let siteId = ganttFilter.filters?.find(x => x.name === 'SiteID')?.value
        if (siteId) {
            let body = {
                siteID: siteId,
                startDate: start,
                endDate: end,
                Filters: refresh ? { filters: [] } : ganttFilter.filters
            }
            let data = await GanttApi.getGanttData(body)
            let chambers = []
            let _originOrders = []
            let ordersFilter = []
            let ordersTypeFilter = []
            if (data && data.data) {
                data.data?.forEach((d) => {
                    if (d.TYPECODE) {
                        ordersTypeFilter.push({ label: utils.getOrderTypeLabel(d.TYPECODE), value: d.TYPECODE })
                        d.TYPECODE = utils.getOrderTypeLabel(d.TYPECODE)
                    }
                    ordersFilter.push({ label: d.ORDNAME, value: d.OrderID })
                    // d['color'] =d
                    _originOrders.push(d)
                    d['Plans'] && d['Plans'].forEach(plan => {
                        plan?.SubPlans?.forEach(sub => {
                            let gloveBox
                            if (sub && sub.ChamberID)
                                gloveBox = sub.ChamberID?.substring(0).charAt(0)
                            if (!chambers.find(x => x.name === 'ch' + sub.ChamberID)) {
                                chambers.push(
                                    {
                                        name: 'ch' + sub.ChamberID,
                                        chamberInternalID: sub.chamberInternalID,
                                        gloveBox: gloveBox ? gloveBox[0] : ''
                                    })
                            }
                        })
                    })
                })

                setOriginOrders(_originOrders)
                let _chambers = chambers.sort((a, b) => a.name.localeCompare(b.name));
                let chambersFilter = []
                _chambers.forEach(ch => {
                    chambersFilter.push({ label: ch.name, value: ch.chamberInternalID })
                })
                let orderNumbers = []
                _chambers.forEach(chamber => {
                    let orders = []
                    let blocks = []
                    data.data.forEach(order => {
                        orderNumbers.push({ label: order.ORDNAME, value: order.ORDNAME })

                        if (order['Plans']) {
                            let _plans = order['Plans'][0].SubPlans.filter(x => 'ch' + x.ChamberID === chamber.name)
                            if (_plans.length > 0) {
                                _plans.forEach(_plan => {
                                    _plan['startDate'] = new Date(_plan.startDate)
                                    _plan['endDate'] = new Date(_plan.endDate)
                                })
                                let _order = {
                                    OrderID: order.OrderID,
                                    ORDNAME: order.ORDNAME,
                                    TYPECODE: order.TYPECODE,
                                    approved: order['Plans'][0].planStatus === 'approvedOrders',
                                    planId: order['Plans'][0]['PlanID'],
                                    // approved:true,
                                    SIBD_ALPH_FIXATION: order.SIBD_ALPH_FIXATION,
                                    SIBD_ALPH_SEEDL: order.SIBD_ALPH_SEEDL,
                                    orderDatesRange: _plans,
                                    color: order.color
                                }
                                orders.push(_order)
                            }
                        } else {
                            console.log('Its block chamber !')
                            if (order.chamberInternalID === chamber.chamberInternalID) {
                                blocks.push(order);
                            }
                        }
                    })
                    chamber['chamberColor'] = chambersColors[chamber.gloveBox - 1]
                    orders = assignColors(orders);
                    chamber['orders'] = orders;
                    chamber['blocks'] = splitTimes(blocks);
                })

                setFilterData(chambersFilter, ordersFilter, ordersTypeFilter)

                setGanttOrders(_chambers)
                // setOrdersNum(orderNumbers)
            }
        }
    }
    function splitTimes(times) {
        let result = [];

        times.forEach(item => {
            const startDate = new Date(item.startDate);
            const endDate = new Date(item.endDate);

            // Check if the range exceeds 24 hours
            if ((endDate - startDate) > (24 * 60 * 60 * 1000)) {
                // First entry for the first day
                const firstDayEnd = new Date(startDate);
                firstDayEnd.setHours(23, 59, 59, 999); // End of the first day

                result.push({
                    ...item,
                    endDate: firstDayEnd.toISOString(),
                });

                // Second entry for the next day
                const nextDayStart = new Date(firstDayEnd);
                nextDayStart.setDate(nextDayStart.getDate() + 1);
                nextDayStart.setHours(0, 0, 1, 0); // Start of the next day

                result.push({
                    ...item,
                    startDate: nextDayStart.toISOString(),
                    endDate: item.endDate,

                });
            } else {
                item.isSplit = true;
                // If less than or equal to 24 hours, push the original object
                result.push(item);
            }
        });
        result.forEach(order => {
            let startDate = new Date(order.startDate);
            let start_hours = startDate.getHours().toString().padStart(2, '0');   // Pads with zero if needed
            let start_minutes = startDate.getMinutes().toString().padStart(2, '0');
            let start_seconds = startDate.getSeconds().toString().padStart(2, '0');

            let endDate = new Date(order.endDate);
            let end_hours = endDate.getHours().toString().padStart(2, '0');   // Pads with zero if needed
            let end_minutes = endDate.getMinutes().toString().padStart(2, '0');
            let end_seconds = endDate.getSeconds().toString().padStart(2, '0');

            order.startTime = `${start_hours}:${start_minutes}:${start_seconds}`
            order.endTime = `${end_hours}:${end_minutes}:${end_seconds}`
        });

        return result;
    }

    // Simple hash function to generate a consistent hash from a string
    function hashString(str) {
        let hash = 0;
        for (let i = 0; i < str.length; i++) {
            hash = str.charCodeAt(i) + ((hash << 5) - hash); // Basic hash
        }
        return hash;
    }

    // Function to convert a hash to a hex color
    function hashToColor(hash) {
        let color = '#';
        for (let i = 0; i < 3; i++) {
            const value = (hash >> (i * 8)) & 0xFF; // Extract part of the hash
            color += ('00' + value.toString(16)).slice(-2); // Convert to hex
        }
        return color;
    }
    function getContrastingFontColor(hexColor) {
        const r = parseInt(hexColor.slice(1, 3), 16);
        const g = parseInt(hexColor.slice(3, 5), 16);
        const b = parseInt(hexColor.slice(5, 7), 16);

        // Calculate luminance using the relative luminance formula
        const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

        // If luminance is greater than 0.5, the color is light, so use black font; otherwise, use white font
        return luminance > 0.5 ? '#000000' : '#FFFFFF';
    }
    function assignColors(objects) {
        return objects.map(obj => {
            // Combine both ORDNAME and OrderID to create a unique hash
            const combinedString = obj.ORDNAME + obj.OrderID;
            const hash = hashString(combinedString); // Generate hash from combined values
            return {
                ...obj,
                color: hashToColor(hash), // Generate color from the hash
                fontColor: getContrastingFontColor(hashToColor(hash)), // Calculate the contrasting font color
            };
        });
    }
    const setFilterData = (chambs, orders, ordersType) => {
        let _searchInputs = [...searchInputs]
        _searchInputs[props.currentUserRole === 'SystemAdmin' ? 1 : 0]['data'] = chambs
        _searchInputs[props.currentUserRole === 'SystemAdmin' ? 2 : 1]['data'] = orders
        _searchInputs[props.currentUserRole === 'SystemAdmin' ? 3 : 2]['data'] = ordersType
        setSearchInputs(_searchInputs)
    }

    const getRange = (end) => {
        let dates = []
        let currentDate = end;
        let sevenDaysAgo = new Date(currentDate);
        sevenDaysAgo.setDate(currentDate.getDate() - 8);
        while (sevenDaysAgo < end) {
            sevenDaysAgo.setDate(sevenDaysAgo.getDate() + 1);
            dates.push({ day: getCurrentDateDay(sevenDaysAgo), date: new Date(sevenDaysAgo) });
        }
        setCurrentRange(dates)
        let date = moment(end).format('MMMM YYYY')
        setCurrentMonth(date)
    }
    const getCurrentDateDay = (_date) => {
        const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        const currentDayIndex = _date.getDay();
        return daysOfWeek[currentDayIndex];
    }

    const getNextWeek = async () => {
        let start = new Date(startDate)
        let end = new Date(endDate)
        let newStartDate = new Date(start);
        newStartDate.setDate(start.getDate() + 1);
        let newEndDate = new Date(end);
        newEndDate.setDate(end.getDate() + 1);
        setStartDate(newStartDate);
        setEndDate(newEndDate);
        getRange(newEndDate)
        let siteId = ganttFilter.filters?.find(x => x.name === 'SiteID')?.value
        await getGanttData(siteId, 'SiteID', newStartDate, newEndDate).then()
    }
    const getLastWeek = async () => {
        let start = new Date(startDate)
        let end = new Date(endDate)
        let newStartDate = new Date(start);
        newStartDate.setDate(start.getDate() - 1);
        let newEndDate = new Date(end);
        newEndDate.setDate(end.getDate() - 1);
        setStartDate(newStartDate);
        setEndDate(newEndDate);
        getRange(newEndDate)
        let siteId = ganttFilter.filters?.find(x => x.name === 'SiteID')?.value
        await getGanttData(siteId, 'SiteID', newStartDate, newEndDate).then()
    }

    const editOrder = async (order, chamberName) => {
        order['chamber'] = chamberName
        let _order = await OrdersApi.getOrder(order.OrderID)
        _order = _order.data
        _order['ChamberID'] = chamberName
        _order['SubPlans'] = _order['Plans']?.find(x => x['PlanID'] === order.planId)?.SubPlans
        let dataArr = utils.arrangePlan(_order)
        let plan = dataArr?.find(x => x['PlanID'] === order.planId)
        if (plan) {
            let _plan = { ..._order, ...plan }
            setCurrentPlan(_plan)
        }
        setCurrentOrder(_order)
        setIsEditOrder(true)
    }
    const datePicker = () => {
        const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
            <div className={'text-[#0D3B53] text-[1.125rem] mx-[2rem] underline cursor-pointer'}
                onClick={onClick} ref={ref}>{currentMonth}</div>
        ))
        return (
            <DatePicker
                calendarClassName={'search-input-date'}
                selected={startDate}
                onChange={(date) => setViewDate(date)}
                customInput={<ExampleCustomInput />}
            />
        );
    }
    const setViewDate = async (date) => {
        let start = JSON.parse(JSON.stringify(date))
        let end = new Date(date.setDate(date.getDate() + 4))
        await setStartDate(new Date(start));
        await setEndDate(end);
        getRange(end)
        let siteId = ganttFilter.filters?.find(x => x.name === 'SiteID')?.value
        await getGanttData(siteId, 'SiteID', new Date(start), end).then()
    }

    const filterOnChangeProg = async (e, type) => {
        // let inputs = [...searchInputs]
        let filters = { ...ganttFilter }
        let statusFilter = filters.filters?.findIndex(x => x.name === type)
        if (statusFilter > -1) {
            if (!e.value) {
                filters['filters'].splice(statusFilter, 1)

            } else {

                filters['filters'][statusFilter]['value'] = e.value
            }
        } else {
            filters.filters.push({ name: type, value: e.value })
        }
        if (type === 'SiteID') {
            if (e.value) {
                setSiteID(e.value)
                setSiteName(e.label)
            } else {
                setGanttOrders([])
                setSiteID("")
                setSiteName("")
                setOriginOrders([])
            }
        }
        await setGanttFilter(filters)
        let siteId = type === 'SiteID' ? e.value : siteID
        await getGanttData(siteId, type, startDate, endDate).then()
    }
    const filterOnChange = async (e, type) => {
        // let inputs = [...searchInputs]
        let filters = { ...ganttFilter }
        let statusFilter = filters.filters?.findIndex(x => x.name === type)
        if (statusFilter > -1) {
            if (!e.target.value) {
                filters['filters'].splice(statusFilter, 1)

            } else {

                filters['filters'][statusFilter]['value'] = e.target.value.value
            }
        } else {
            filters.filters.push({ name: type, value: e.target.value.value })
        }
        if (type === 'SiteID') {
            if (e.target.value) {
                setSiteID(e.target.value.value)
                setSiteName(e.target.value.label)
            } else {
                setGanttOrders([])
                setSiteID("")
                setSiteName("")
                setOriginOrders([])
            }
        }
        await setGanttFilter(filters)
        let siteId = type === 'SiteID' ? e.target.value?.value : siteID
        await getGanttData(siteId, type, startDate, endDate).then()
    }
    const approvePlane = async (order) => {
        let result = await OrdersApi.approvePlan(order.planId, { orderid: String(order.OrderID), affectedOrders: [] })
        if (result) {
            //'SiteID'
            let siteId = ganttFilter.filters?.find(x => x.name === 'SiteID')?.value
            await getGanttData(siteId, 'SiteID').then()
        }

    }

    return (
        <div className=" w-width80 h-full pt-32 ml-24 font-Roboto">
            <div className={"site-search-inputs"} style={{ display: 'block' }}>
                <div className={'flex justify-between'}>
                    <div className={"site-search-inputs"}>
                        {searchInputs.map(item => {
                            return <SelectAlphaIcon
                                key={item.name}
                                svgName={item.svgName}
                                data={item.data}
                                type={item.type}
                                value={ganttFilter['filters']?.find(filter => filter.name === item.name)?.value}
                                // selected={chambersFilter['filters'].find(filter=>filter.name===item.name)?.value}
                                name={item.name}
                                label={item.label}
                                isRange={true}
                                inputWidth={'20rem'}
                                onChange={(e) => filterOnChange(e, item.name)}
                                // onChangeDate={onChangeDateFilter}
                                startDate={startDate}
                                endDate={endDate}
                                hasLine={item.hasLine}
                                inputClassName={'Select-Icon'}
                            />
                        })}

                    </div>
                    <SVGraphics className={'w-[3.5rem] mr-4 cursor-pointer'} svgname={'notes'}
                        onClick={() => setIsGanttNote(!!siteID)} />
                </div>
                {
                    // (siteName || chamberName) 
                    // && 
                    (<>
                        <div className={'w-[100%] flex justify-center my-[1rem] items-center'}>
                            <div className={'flex items-center'}>
                                <div onClick={() => getLastWeek()}><SVGraphics className={'w-[2rem] mr-[0.75rem]'}
                                    svgname={'leftArrow'} /></div>
                                {datePicker()}
                                <div onClick={() => getNextWeek()}><SVGraphics className={'w-[2rem] mr-[0.75rem]'}
                                    svgname={'rightArrow'} /></div>
                            </div>
                        </div>
                        <div>
                            <div className={'grid grid-cols-9'}>
                                <div className={'w-[143px]'} />
                                {currentRange.map(range => {
                                    return <div
                                        className={`font-Roboto font-medium text-base leading-6 text-center ${range.day === 'Saturday' ? 'bg-[#D7DDDF]' : 'bg-blue-300  bg-opacity-20'} text-blue-800 border border-solid border-gray-300 relative right-[17px]`}
                                        key={range.date}>
                                        <div>{range.day}</div>
                                        <div>{moment(range.date).format('DD MMM YYYY')}</div>
                                    </div>
                                })
                                }
                            </div>
                            <OrdersGanttView orders={ganttOrders} currentRange={currentRange}
                                editOrder={editOrder}
                                currentUser={currentUser}
                                approvePlane={approvePlane}
                            />
                            <div className={'w-[98.8%] h-[10px] bg-alphaTauButton'} />
                            <DeliveryGanttView currentRange={currentRange} orders={originOrders} siteName={siteName} />
                        </div>

                    </>)

                }
                {/* {
                    authenticationService.currentUserValue?.role[0] === "SystemAdmin" && !siteName && (
                        <div className={'text-xl font-bold text-alphaTauText'}>
                            Please select site ↑
                        </div>
                    )
                }
                {
                    authenticationService.currentUserValue?.role[0] !== "SystemAdmin" && !chamberName && (
                        <div className={'text-xl font-bold text-alphaTauText'}>
                            Please select chamber ↑
                        </div>
                    )
                } */}
                {isEditOrder &&
                    <EditOrderPlan
                        order={currentPlan}
                        orderId={currentOrder.OrderID}
                        // sheddingLoss={location.state.sheddingLoss}
                        siteId={siteID}
                        cancelEdit={() => {
                            setIsEditOrder(false)
                            setCurrentOrder(null)
                            setCurrentPlan(null)
                        }}
                        planSaved={() => planSaved()}
                    />
                }
                {isGanttNote && siteID &&
                    <GanttNotes
                        onCancel={() => setIsGanttNote(false)}
                        order={currentOrder}
                        user={currentUser}
                        siteID={siteID}
                    />
                }
            </div>
        </div>
    );
}

