import React, {useEffect, useState} from "react";
import SystemAdmin from "../../services/ApiServices/SystemAdminApi";
import {TextAlpha} from "../input/TextAlpha";
import {AsymptoticActivityForm} from "./configurations/AsymptoticActivityForm";
import {CalculationForm} from "./configurations/CalculationForm";
import {authenticationService} from "../../services/AuthinticationService";
import {MeasurementForm} from "./configurations/MeasurementForm";


export function Configurations() {
    const labelClass = "font-Roboto text-[1.313rem] text-alphaTauText break-words overflow-hidden h-auto text-clip pl-[6.125rem] max-w-[45%] lg:max-w-[45%] md:w-full md:max-w-full md:pl-2 md:pb-2 lg:pl-0 lg:pb-0 "
    const inputClass = "pl-4 w-[21.75rem] bg-[#FFFFF] rounded-xl h-14 font-Roboto text-alphaTauText border border-[#D7DDDF] "
    const inputContainerClass = "mt-4 flex md:flex-col lg:items-center justify-between md:items-start lg:flex-row w-[70%] lg:w-[100%] xl:w-[100%] 2xl:w-[70%] md:w-[100%] py-4 px-4 rounded-[10px] pr-6 items-center"
    const configurationOptions = [{name:"asymptotic_activity",label:"Asymptotic activity settings "},{name: "calculator",label: "Calculator settings "},{name:"measurementApp",label:"Measurement app settings"}]
    // const [sites, setSites] = useState(null);
    const [activeKey, setActiveKey] = useState([]);
    const [sites, setSites] = useState([]);
    const [currentUser, setCurrentUser] = useState(null);
    const role=authenticationService.currentUserValue?.role[0]

    useEffect( () => {
        setCurrentUser(authenticationService.currentUserValue)
        //if(role==="SystemAdmin"){
            fetchData().then(p=> {
                let _sites = []
                p.data.forEach(site => {
                    _sites.push({value: site.SiteID, label: site.SiteName})
                })
                setSites(_sites)
            })
        //}

    }, [])
    
    const fetchData= async()=> {

        return await SystemAdmin.getAllSitesAdvanced({filters:[]})
    }
    const openCollapse = (coll) => {
        let activeKeyCopy = [...activeKey]
        let activeIndex = activeKey.findIndex(x=>x===coll)
        if (activeIndex>-1) {
            activeKeyCopy.splice(activeIndex,1)
        } else {
            activeKeyCopy.push(coll)
        }
        setActiveKey(activeKeyCopy)
    }
    const getForm = (type) => {

        //AsymptoticActivityForm
        switch (type){
            case "asymptotic_activity":
                return <AsymptoticActivityForm
                    // onChange={(e)=>onChange(e.target.name, e.target.value)}
                    // data={entry}
                    currentUser={currentUser}
                    classes ={{labelClass:labelClass,inputClass:inputClass,inputContainerClass:inputContainerClass}}
                />

                case "calculator":
                return <CalculationForm
                    role={role}
                    // onChange={(e)=>onChange(e.target.name, e.target.value)}
                    // data={entry}
                    currentUser={currentUser}
                    sites={sites}
                    classes ={{labelClass:labelClass,inputClass:inputClass,inputContainerClass:inputContainerClass}}
                />

                case "measurementApp":
                return <MeasurementForm
                    role={role}
                    sites={sites}
                    currentUser={currentUser}
                    // onChange={(e)=>onChange(e.target.name, e.target.value)}
                    // data={entry}
                    classes ={{labelClass:labelClass,inputClass:inputClass,inputContainerClass:inputContainerClass}}
                />

            default:
                return type
        }

    }

return(
    <div className=" w-width80 h-full pt-32 ml-24 font-Roboto overflow-auto">
        <TextAlpha text={"Configurations"}
                   className={"text-alphaTauText text-[1.688rem] text-titleSize cursor-pointer font-bold"}
        />
        <div className={'mt-[1.875rem]'}>
            {
                configurationOptions.map(option=>{
                    return <div key={option.name} className={'w-[90%] mb-[1.25rem] bg-alphaTauButton rounded-[0.5rem]'}>
                        <div
                             className={` flex w-[100%] h-[3.5rem] text-[1.313rem] text-[#F2F2F2] rounded-[0.5rem] items-center pl-[7.125rem] flex justify-between w-[90%]`}>
                            {option.label}
                            <button className={`flex items-center w-[25%] justify-end`} onClick={() => openCollapse(option.name)}
                            >
                                {
                                    <div className={`text-[1.125rem] font-bold cursor-pointer`}
                                         style={{transform: activeKey === option.name ? 'rotate(270deg)' : 'rotate(90deg)'}}>&#8250;</div>
                                    // <SVGraphics className="h-10" svgname={"arrowBlackDown"} onClick={()=>openCollapse(plan.Name)}/>
                                }
                            </button>
                        </div>

                        {activeKey.find(x=>x=== option.name)  &&
                        <div className={`flex w-[100%] h-[30rem] rounded-b-[0.5rem] justify-start bg-[#FAFAFA] border border-[#EDEDED] `}>{getForm(option.name)}</div>

                        }
                    </div>

                })
            }
        </div>

    </div>
)
}