import React, { useEffect, useState } from "react";
import { FormCard } from "../../forms/FormCard";
import formData from "../../forms/formsData.json";
import { AlphaForm } from "../../forms/AlphaForm";
import { TextAlpha } from "../../input/TextAlpha";
import { useNavigate, useParams } from "react-router-dom";
import { object, string, date, number } from "yup";
import Constants from "../../../utils/constants";
import OrdersApi from "../../../services/ApiServices/OrdersApi";
import { FooterSaveButtons } from "../../input/FooterSaveButtons";
import { AlertConfirm } from "../../modals/Confirm";
import moment from "moment";
import { authenticationService } from "../../../services/AuthinticationService";


export function CalculateOrder() {
    const navigate = useNavigate();
    const [formType, setFormType] = useState(formData['calculate_order'])
    const [editOrderForm, setEditOrderForm] = useState(formData['edit_order'])
    const [entry, setEntry] = useState({});
    const [error, setError] = useState({})
    const [isError, setIsError] = useState(false)
    const [editedData, setEditedData] = useState({})
    const [disabled, setDisabled] = useState(false)
    const [hasPlan, setHasPlan] = useState(false)
    const [plan, setPlan] = useState({})
    const [userRole, setUserRole] = useState(null);

    let params = useParams();
    const right = <span className={"text-[#444444] text-2xl text-titleSize ml-2"}>&#8250;</span>
    //
    const orderSchema = object().shape({
        EDATE: date().required('Please insert Delivery time').test('checkEndDate', '', function (value) {
            const { parent, createError } = this;
            if ((parent.ReadyDT)?.getTime() >= value.getTime()) {
                return createError({
                    path: 'EDATE',
                    message: 'Pick up time must be after Ready time',
                });
            } else if (isBelowTwoHours((parent.ReadyDT), value)) {
                return createError({
                    path: 'EDATE',
                    message: 'Pick up time must at least 2h after Ready time ',
                });
            }
            return true;
        }),
        SIBD_TREATDAY: date().required('Please insert Procedure time'),
        ReadyDT: date().required('Please insert Ready time').test('checkEndDate', '', function (value) {
            const { parent, createError } = this;
            if (value.getTime() >= (parent.EDATE)?.getTime()) {
                return createError({
                    path: 'ReadyDT',
                    message: 'Ready time must be before Pick up time ',
                });
            } else if (isBelowTwoHours(value, (parent.EDATE))) {
                return createError({
                    path: 'ReadyDT',
                    message: 'Ready time must at least 2h before Pick up time ',
                });
            }
            return true;
        }),
        ALPH_EXTRA: number().optional().nullable().typeError('Please insert Number'),
        MaxCharging: number().required('Please insert Max charging').default(0).min(0, 'must be positive number'),
        CUSTDES: string().required('Please insert Procedure site').default(""),
        SBD_PREFACTIV: string().required('Please insert activity level').default("")
    });


    useEffect(() => {
        let _form = JSON.parse(JSON.stringify(formType))
        let _editOrderForm = JSON.parse(JSON.stringify(editOrderForm))
        let user = authenticationService.currentUserValue
        setUserRole(user.role[0])
        let id = params.id

        function isValidDate(d) {
            return d instanceof Date && !isNaN(d);
        }

        if (id) {
            getData(id).then(data => {
                let maxCharging
                let _order = JSON.parse(JSON.stringify(data.data))
                let chargeDifference = _order.chargeDiff ? Number(_order.chargeDiff) : 0
                if (_order.SIBD_ALPH_FIXATION === "Coated") {
                    _order["sheddingLoss"] = _order.coatedLoss
                } else {
                    _order["sheddingLoss"] = _order.bakeLoss
                }
                maxCharging = Number(_order.maxTimeForCharge)
                let pickUpDate
                if (_order['EDATE']) {
                    pickUpDate = new Date(_order['EDATE'])
                }
                if (isValidDate(pickUpDate)) {
                    let pickUpTime = _order.SIBD_COLLECTTIME.split(":")

                    pickUpDate = new Date(pickUpDate.setHours(Number(pickUpTime[0]), Number(pickUpTime[1]), Number(pickUpTime[2])))
                    _order['EDATE'] = pickUpDate

                }
                _order['SIBD_TREATDAY'] = new Date(_order['SIBD_TREATDAY'])

                if (_order['SIBD_TREATDAY']) {
                    let time = _order['SIBD_TREATTIME'].split(':')
                    _order['SIBD_TREATDAY'].setHours(time[0], time[1], time[2])
                }

                let readyDate
                if (data.data['EDATE']) {
                    readyDate = reduceHours(_order['EDATE'], chargeDifference)
                }
                _order['ReadyDT'] = readyDate
                _order['DeliveryDT'] = data.data['EDATE']
                _order['MaxCharging'] = maxCharging
                // console.log("_order['EDATE']",moment(_order['EDATE']))
                let checkIfHasPlan = _order['Plans'] && _order['Plans'].length > 0
                setPlan(_order['Plans'][0])
                setEntry(_order)
                setHasPlan(checkIfHasPlan)
                if (_order.TYPECODE === "008") {
                    if (user.role[0] !== 'TeamLeader' && _order['ORDSTATUSDES'] !== "Approved") {
                        _form[0]['disabled'] = false
                        _form[1]['disabled'] = false
                        _form[3]['disabled'] = false
                        setFormType(_form)
                    }
                }
                validateOrder(_order)
                if (_order['SIBD_TREATDAY'] < new Date()) {
                    setDisabled(true)
                }
                if (user.role[0] === 'TeamLeader' && _order['ORDSTATUSDES'] === "Approved") {
                    setDisabled(true)
                    _editOrderForm.forEach(field => {
                        field['disabled'] = true
                    })
                    _form.forEach(field => {
                        field['disabled'] = true
                    })
                }
                let test = _order['SIBD_TREATDAY'];
                _order['EDATE_MAX'] = _order['SIBD_TREATDAY']

                setFormType(_form)
                setEditOrderForm(_editOrderForm)
            })

        }

    }, [params.id])
    // const getMaxChargingConfige = async (siteId) => {
    //     let data = await ConfigurationApi.getCalculatorConfiguration(siteId)
    //     if(data && data.data){
    //         return data.data
    //     }
    // }
    const reduceHours = (date, hours) => {
        const newDate = new Date(date);
        newDate.setHours(newDate.getHours() - hours);
        return newDate;
    };

    const getData = async (id) => {
        return await OrdersApi.getOrder(id)
    }
    // const isValidDate = (d1, d2) => {
    //     const twoHoursInMilliseconds = 2 * 60 * 60 * 1000;
    //     return (d2 - d1) >= twoHoursInMilliseconds;
    // };
    const calculateOrder = async () => {
        // console.log("editedData",editedData)
        // let readyDate = entry['SIBD_COLLECTTIME']
        // let deliveryDate = entry['EDATE']
        // let isValid = isValidDate(readyDate,deliveryDate)
        // console.log("isValid",isValid)
        let confirmApprove = !hasPlan
        if (hasPlan) {
            confirmApprove = await AlertConfirm({
                proceedLabel: 'Yes',
                cancelLabel: 'No',
                options: { hasSVG: true, svgName: "alert-triangle" },

            }, "Are you sure you want to re-calculate?", `Order ${entry.ORDNAME} has already approved plan`)
        }
        if (confirmApprove) {
            let orderToCalculate = {
                orderid: String(entry?.OrderID),
                siteid: entry?.SiteID,
                ReadyDT: entry?.ReadyDT.toISOString(),
                DeliveryDT: entry?.EDATE.toISOString(),
                MaxCharge: Number(entry?.MaxCharging),
                ExtraSeed: String(entry?.ALPH_EXTRA),
                sheddingLoss: entry?.sheddingLoss
            }
            let calculateOrder = await OrdersApi.CalcPlans(orderToCalculate)
            if (calculateOrder && calculateOrder.status === 200) {
                let orderEdited = Object.keys(editedData).length > 0
                navigate(`/orders/orderPlans/${params.id}`,
                    {
                        state: {
                            SiteID: entry.SiteID,
                            ORDNAME: entry.ORDNAME
                            , orderID: params.id,
                            isCalculate: true,
                            calculateOrder: calculateOrder.data,
                            orderEdited: orderEdited,
                            editedData: editedData
                        }
                    })
            }
        }
    }

    function isBelowTwoHours(date1, date2) {
        const timeDifference = Math.abs(new Date(date1)?.getTime() - new Date(date2)?.getTime());
        const twoHoursInMilliseconds = 2 * 60 * 60 * 1000; // 2 hours in milliseconds

        return timeDifference < twoHoursInMilliseconds;
    }

    function isBelowFifteenHours(date1, date2) {
        const timeDifference = Math.abs(date1?.getTime() - date2?.getTime());
        const fifteenHoursInMilliseconds = 15 * 60 * 60 * 1000; // 15 hours in milliseconds

        return timeDifference < fifteenHoursInMilliseconds;
    }

    const displayAlert = async (text, svg) => {
        let message = <div>{text}</div>
        return await AlertConfirm({
            proceedLabel: 'Ok',
            cancelLabel: 'none', options: { hasSVG: true, svgName: svg }
        }, message, '')
    }

    const validateOrder = (order) => {
        let form = JSON.parse(JSON.stringify(editOrderForm))
        let formErrors = { ...error }
        orderSchema.validate(order, { abortEarly: false }).then(function (value) {
            setIsError(false)
        })
            .catch(function (err) {
                let index = form.findIndex(x => x.name === error.path)
                if (index > -1) {
                    err.inner?.forEach(error => {
                        formErrors[error.path] = true
                        form[index]['error'] = error.errors
                        setDisabled(true)
                        setIsError(true)

                    })
                }

            });
        setEditOrderForm(form)
        setError(formErrors)
    }

    const onChange = async (name, value) => {
        let _editedData = { ...editedData }
        let order = { ...entry }
        let form = JSON.parse(JSON.stringify(editOrderForm))
        let formErrors = { ...error }
        let isPass = true

        if (name === 'ReadyDT' || name === 'EDATE') {
            let oldTime = order[name]
            let date = name === 'ReadyDT' ? order['EDATE'] : order['ReadyDT']

            if (isBelowTwoHours(value, date)) {
                let isTwoHours = displayAlert('The Ready time must be at least 2 hours from the delivery time', 'red-warning-circle')
                if (isTwoHours) {
                    order[name] = oldTime
                }
            } else if (isBelowFifteenHours(value, date)) {
                let is15Hours = displayAlert('Please note that the delivery time is below 15 hours before ready time.', 'alert-triangle')
                if (is15Hours) {
                    order[name] = value
                }
            } else {
                order[name] = value
            }
        } else {
            if (name === 'MaxCharging' && !value) {
                order[name] = 0
            } else {
                order[name] = value
            }
        }
        await orderSchema.validate(order, { abortEarly: false }).then(function (value) {
            formErrors[name] = false
            setDisabled(false)
            setIsError(false)
        })
            .catch(function (err) {
                let index = form.findIndex(x => x.name === name)
                if (index > -1) {
                    err.inner?.forEach(error => {
                        if (error.path === name) {
                            formErrors[error.path] = true
                            form[index]['error'] = error.errors
                            setDisabled(true)
                            setIsError(true)
                            isPass = false
                        }
                    })
                }

                let errorIndex = err.inner?.findIndex(x => x.path === name)
                if (errorIndex === -1 && index > -1) {
                    formErrors[name] = false
                    form[index]['error'] = []
                }

            });
        setEntry(order)
        setEditOrderForm(form)
        setError(formErrors)
        if ((name === 'EDATE' || name === 'SIBD_TREATDAY') && isPass) {
            _editedData[name] = order[name].toISOString()
            if (name === 'EDATE') {
                _editedData['SIBD_COLLECTTIME'] = moment(order[name]).format('HH:mm:ss')
            }
        } else {
            _editedData[name] = value
        }

        setEditedData(_editedData)
    }
    const cancelEdit = async () => {
        navigate('/orders')
    }
    const viewPlan = async () => {
        navigate(`/orders/plan/${plan.Name}`, {
            state: {
                SiteID: entry.SiteID,
                ORDNAME: entry.ORDNAME,
                plan: {},
                orderID: params.id,
                planId: plan['PlanID'],
                viewFrom: "order",
                viewPlan: true,
                orderStatus: entry['ORDSTATUSDES'],
                orderId: entry.OrderID,
                sheddingLoss: entry.sheddingLoss
            }
        })
    }

    console.log("SIBD_TREATDAY", entry['SIBD_TREATDAY'])

    return (
        <div className="w-full h-full pt-32 ml-24 overflow-auto">

            <div className={'w-11/12 mb-8'}>
                <div className={'mb-7'}>
                    <TextAlpha text={"Orders"}
                        className={"text-alphaTauText text-[1.688rem] text-titleSize cursor-pointer font-bold"}
                        onClick={() => navigate('/orders')}
                    />
                    <TextAlpha text={right} />
                    <TextAlpha text={" Order №: " + entry['ORDNAME']} className={"text-alphaTauButton text-[1.5rem] "} />
                </div>

                <div>
                    <FormCard
                        cardClass={'pb-[1.25rem] mb-[1.5rem]'}
                        cardTitle={"Fix order details"}
                        data={<> <AlphaForm
                            data={formType}
                            isClearable={'false'}
                            formData={entry}
                            onChange={e => onChange(e.target.name, e.target.value)}
                            containerClassName={"mb-6 mr-12"}
                            labelClassName={Constants.inputLabelClass}
                            inputClassName={Constants.inputClass}
                            isShown={error}
                            isError={isError}
                        />
                        </>
                        }
                    />
                    <FormCard
                        cardClass={'pb-[1.25rem] mb-[1.5rem]'}
                        cardTitle={"Editable order details"}
                        data={
                            <>
                                <AlphaForm
                                    data={editOrderForm}
                                    isClearable={userRole === 'TeamLeader' && entry['ORDSTATUSDES'] === "Approved" ? 'false' : 'true'}
                                    formData={entry}
                                    onChange={e => onChange(e.target.name, e.target.value)}
                                    containerClassName={"mb-6 mr-12"}
                                    labelClassName={Constants.inputLabelClass}
                                    inputClassName={Constants.inputClass}
                                    isShown={error}
                                    isError={isError}
                                />
                            </>
                        }
                    />

                </div>


            </div>
            <div className={'flex pr-14 '}>

                <FooterSaveButtons
                    containerClass={'lg:flex-row justify-between md:items-start items-end lg:items-end w-11/12'}
                    disabledSave={disabled}
                    onClickSave={() => calculateOrder()}
                    saveLabel={hasPlan ? 'ReCalculate' : 'Calculate'}
                    onClickCancel={() => cancelEdit()}
                    cencelLabel={'Back'}
                    children={hasPlan ? <button type="button"
                        // disabled={props.disabledSave}
                        onClick={() => viewPlan()}
                        className={`${Constants.buttonClass} mr-12 lg:mr-12 md:ml-0 w-loginButton text-white bg-alphaTauButtonSecondary hover:bg-alphaTauButtonHover font-Roboto font-medium rounded-rounded10 text-textSize px-5 py-2.5 text-center h-[3.5rem]`}>
                        View Plan
                    </button> :
                        <div
                            className={`${Constants.buttonClass} mr-12 w-loginButton text-white font-Roboto font-medium rounded-rounded10 text-textSize px-5 py-2.5 text-center h-[3.5rem]`} />
                    }
                />
            </div>

        </div>
    )


}