import React, {useEffect, useState} from "react";
import {TextAlpha} from "../../../input/TextAlpha";
import {useNavigate, useParams} from "react-router-dom";
import formData from "../../../forms/formsData.json";
import {AlphaForm} from "../../../forms/AlphaForm";
import {array, object, string} from "yup";
import Constants from "../../../../utils/constants";
import {v4 as uuidv4} from 'uuid';
import SystemAdmin from "../../../../services/ApiServices/SystemAdminApi";
import {FormCard} from "../../../forms/FormCard";
import {Country} from "country-state-city";
import {AlertConfirm} from "../../../modals/Confirm";

export function EditSite() {
    const params = useParams();
    const navigate = useNavigate();
    const phoneRegExp = Constants.phoneRegExp
    const [entry, setEntry] = useState({
        ActiveChambers: '0',
        productionAdmin: [{id: uuidv4() + "-" + new Date().getTime()}]
    });
    const [adminErrorsArr, setAdminErrorsArr] = useState([])
    const [isSaveAvailable,setSaveAvailable] = useState(false)
    const [error, setError] = useState({})
    const [isError, setIsError] = useState(false)
    const [productionAdminForms, setProductionAdminForms] = useState([formData['add_Production_Admin']])
    const [formType, setFormType] = useState(formData['edit_site'])
    const siteSchema = object().shape(
        {
            SiteName: string().required('Please insert Name').default(""),
            prioritySiteID: string().required('Please insert priority Site ID').default(""),
            Country: object().required().typeError('Please insert Country'),
            ZIP: string().required('Please insert ZipCode').typeError('Please insert ZipCode').default(""),
            City: object().required().default({}),
            Street: string().required().typeError('Please insert Street').default(""),
            Number: string().required().typeError('Please insert Number').default(""),
            ActiveChambers: string().required('Please insert Chambers').typeError('Please insert Chambers').default(""),
            Phone: string(),
            technicalPersonFirstName: string().required('Please insert First Name').default(""),
            technicalPersonLastName: string().required('Please insert Last Name').default(""),
            technicalPersonPhoneNumber: string().required('Please insert Phone Number').typeError('Please insert Phone Number').matches(phoneRegExp, 'Phone number is not valid').default(""),
        })
    const productionAdminSchema = object().shape({
        FirstName: string().required('Please insert First Name').default(""),
        LastName: string().required('Please insert Last Name').default(""),
        Email: string().required('Please insert Email').matches(Constants.emailRegExp, "Please insert Valid Email").nullable(),
        PhoneNumber: string().required('Please insert Phone Number').typeError('Please insert Phone Number').matches(phoneRegExp, 'Phone number is not valid').default(""),
    });

    useEffect(() => {
        fetchData().then(response => {
            let site = response.data
            let form = JSON.parse(JSON.stringify(formType))
            form.map(f=>{
                f['inputsArr'].forEach(input=>{
                    console.log("input",input)
                    console.log("input.name",input.name)
                    console.log("site['IsActive']",site['IsActive'])
                    if((input.name !== "IsActive" && input.name !== "Phone" && input.name !== "prioritySiteID" ) || !site['IsActive'] ){
                        input['disabled'] = true
                    }
                })
            })
            let country = (Country.getAllCountries()).find(country =>country.name ===  site['Country'])
            if(country){
                site['Country'] = {label:site['Country'],value:country?.isoCode}
                site['State'] = {label:site['State'],value:site['State']}
                site['City'] = {label:site['City'],value:site['City']}
            }
            let admins = site['productionAdmin']
            let adminsFormsArr=[]
            admins.forEach((admin,index)=>{
                let adminForm = formData['add_Production_Admin']
                admin['isExist'] = true
                let __formData = JSON.parse(JSON.stringify(adminForm))
                __formData.forEach(d=>{
                    d['disabled'] = true
                })
                adminsFormsArr.push(__formData)
            })

            setFormType([...form])
            setProductionAdminForms([...adminsFormsArr])
            setEntry(site)
            setSaveAvailable(site.IsActive)
        }).catch(e=> {
            console.log("e",e)
                navigate('/systemAdmin/sites')
            })


    },[])

    const fetchData = async () => {
        let id =params.id
        return await SystemAdmin.getSite(id)
    }

    const arrayOfProductionAdminSchema =
        array().of(productionAdminSchema)

    const validateForm = async (currentEntry) => {
        let value = false
        let errorObj = {}
        await siteSchema.validate(currentEntry, {abortEarly: false})
            .catch(async function (err) {
                for (const error of err.inner) {
                    let errorText = error.errors
                    let path = error.path
                    errorObj[path] = errorText[0]
                }
                setError({...errorObj})
                setIsError(true)
                value = true
            });
        setError({...errorObj})
        return value
    }

    const validateAdminsArr = async () => {
        let value = false
        let currentArr = entry['productionAdmin']
        let adminErrors = currentArr.map(() => {
            return []
        })
        await arrayOfProductionAdminSchema.validate(currentArr, {abortEarly: false})
            .catch(async function (err) {
                for (const error of err.inner) {
                    let index = error.path.substring(1, 2)
                    let path = error.path.substring(4)
                    adminErrors[index][path] = error.errors
                }
                value = true
                setAdminErrorsArr([...adminErrors])
            });
        setAdminErrorsArr(adminErrors)

        return value
    }

    const onChange = async (name, value) => {
        let currentEntry = {...entry}
        if (value === "") {
            value = null
        }
        currentEntry[name] = value
        setEntry({...currentEntry})
        if (error[name]) {
            await validateForm(currentEntry)
        }
    }
    const onProdAdminChange = async (name, value, index) => {
        let currentEntry = {...entry}
        if (value === "") {
            value = null
        }
        currentEntry['productionAdmin'][index][name] = value
        if (index < adminErrorsArr.length && adminErrorsArr[index][name]) {
            await validateAdminsArr(entry)
        }
        setEntry({...currentEntry})
    }

    const addAdminForm = () => {
        let currentEntry = {...entry}
        let currentForm = [...productionAdminForms]
        currentEntry['productionAdmin'].push({id: uuidv4() + "-" + new Date().getTime()})
        currentForm.push(formData['add_Production_Admin'])
        setEntry({...entry})
        setProductionAdminForms([...currentForm])
    }

    const removeAdminForm = (id) => {
        let currentEntry = {...entry}
        let currentForm = [...productionAdminForms]
        currentEntry['productionAdmin'].splice(id, 1)
        currentForm.splice(id, 1)
        setProductionAdminForms([...currentForm])
        setEntry({...currentEntry})
    }

    const editSite = async () => {
        let validateSite = await validateForm(entry)
        let validateAdmins = await validateAdminsArr(entry)
        if (!validateAdmins && !validateSite) {
            let ObjToSend = {...entry}
            ObjToSend['Country'] = ObjToSend['Country'].label
            ObjToSend['State'] = ObjToSend['State']?.label ? ObjToSend['State']?.label : ObjToSend['Country']
            ObjToSend['City'] = ObjToSend['City']?.label ? ObjToSend['City']?.label : ObjToSend['Country']
            let siteInfo = {
                SiteInfo: ObjToSend,
                productionAdmins: entry['productionAdmin']
            }
            let confirm=false
            if(!ObjToSend['IsActive']){
                confirm = await AlertConfirm({
                    proceedLabel: 'Ok',
                    cancelLabel: 'Cancel',
                    options: {hasSVG: true, svgName: "red-warning-circle"}
                }, 'Are you sure you want to disable this site','Disable Site',)
            }
            if(confirm ||ObjToSend['IsActive']){
                let submit = await SystemAdmin.editSite(siteInfo,ObjToSend['SiteID'])
                if (submit && submit.data) {
                    navigate('/systemAdmin/sites')
                }
            }
        }
    }
    const cancelEdit = async () => {
        navigate('/systemAdmin/sites')
    }

    return (
        <div className="w-full h-full pt-32 ml-24 overflow-auto">
            <div className={'w-11/12'}>
            <div className={'mb-7'}>
                <TextAlpha text={"Sites"}  className={"text-alphaTauText text-xl text-titleSize cursor-pointer"} onClick={()=>navigate('/systemAdmin/sites')}/>
                <TextAlpha text={" > "}  className={"text-alphaTauText text-xl  text-titleSize"}/>
                <TextAlpha text={" Edit Site"}  className={"text-alphaTauButton text-xl text-titleSize"}/>
            </div>
                {formType.map((item,index) => {
                    return <FormCard
                        id={item['key']}
                        cardClass={'pb-[1.25rem] mb-[1.5rem]'}
                        cardTitle={item['text']}
                        data={<> <AlphaForm
                            data={item['inputsArr']}
                            formData={entry}
                            index={index}
                            onChange={e => onChange(e.target.name, e.target.value)}
                            containerClassName={"mb-6 mr-12"}
                            labelClassName={Constants.inputLabelClass}
                            inputClassName={Constants.inputClass}
                            isShown={error}
                            isError={isError}
                        />
                        </>
                        }

                    />
                })}
                {productionAdminForms.map((item, index) => {
                    let adminEntry = entry['productionAdmin']?entry['productionAdmin'][index]:{}
                    return <FormCard
                        id={"ProductionAdmin" + index}
                        cardClass={'pb-[1.25rem] mb-[1.5rem]'}
                        cardTitle={"Production admin info"}
                        hasDeleteIcon={item['isExist']}
                        length={productionAdminForms.length}
                        deleteCurrent={() => removeAdminForm(index)}
                        data={<>
                            <AlphaForm
                                data={item}
                                index={"-ProductionAdmin" + adminEntry.UserStorageId}
                                formData={adminEntry}
                                onChange={e => onProdAdminChange(e.target.name, e.target.value, index)}
                                containerClassName={"mb-6 mr-12"}
                                labelClassName={Constants.inputLabelClass}
                                inputClassName={Constants.inputClass}
                                isShown={adminErrorsArr[index]}
                                isError={isError}
                            />
                        </>
                        }

                    />
                })}

                <div className={'float-right my-8 flex items-center pb-[3.125rem]'}>
                    {isSaveAvailable && <button type="button"
                             onClick={() => editSite()}
                             className="mr-12 w-loginButton text-white bg-alphaTauButton hover:bg-alphaTauButtonHover font-Roboto font-medium rounded-rounded10 text-textSize px-5 py-2.5 text-center">
                        Edit
                    </button>}
                    <div
                        className={'mr-10 underline underline-offset-4 text-alphaTauButton cursor-pointer text-[1.313rem]'}
                        onClick={() => cancelEdit()}>Cancel
                    </div>
                </div>
            </div>
        </div>
    )
}