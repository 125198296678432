import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import formData from "../../../forms/formsData.json";
import { v4 as uuidv4 } from "uuid";
import { array, object, string } from "yup";
import { TextAlpha } from "../../../input/TextAlpha";
import { FormCard } from "../../../forms/FormCard";
import { AlphaForm } from "../../../forms/AlphaForm";
import SVGraphics from "../../../../assets/SVGraphics";
import Constants from "../../../../utils/constants";
import { authenticationService } from "../../../../services/AuthinticationService";
import SystemAdmin from "../../../../services/ApiServices/SystemAdminApi";
import { SystemAdminApi } from "../../../../services/ApiService";


export function AddUser() {
    const right = <span className={"text-[#444444] text-2xl text-titleSize ml-2"}>&#8250;</span>
    const navigate = useNavigate();
    const [entry, setEntry] = useState({ userSites: [{ id: uuidv4() + "-" + new Date().getTime() }] });
    const [userSiteForm, setUserSiteForm] = useState([formData['user_sites']])
    const [userSiteErrArr, setUserSiteErrArr] = useState([])
    const [error, setError] = useState({})
    const [isError, setIsError] = useState(false)
    const [sitesOptions, setSitesOptions] = useState([])
    const formType = formData['add_user']

    const userSchema = object().shape({
        FirstName: string().required('Please insert First Name'),
        LastName: string().required('Please insert Last Name'),
        Gender: string().required('Please insert Gender'),
        PhoneNumber: string().nullable(),
        Email: string().matches(Constants.emailRegExp, "Invalid Email").required("Please insert Email"),
    });

    const userSiteSchema = object().shape({
        SiteID: string().required('Please select Site'),
        Role: string().required('Please select Role'),
    })

    const arrayOfUserSiteSchema =
        array().of(userSiteSchema)

    useEffect(() => {
        let currentUser = authenticationService.currentUserValue
        let userSitesFormArr = [...userSiteForm]
        let sitesArr = []
        // TODO check roles option for diff users
        // let rolesDataArr = userSitesFormArr[0][1].data?.filter(x=> x.value !== "SystemAdmin")
        if (currentUser.role[0] === "SystemAdmin") {
            fetchData().then(p => {
                p.data.forEach(site => {
                    if (site['IsActive']) {
                        sitesArr.push({ value: site.SiteID, label: site.SiteName })
                    }
                })
                userSitesFormArr[0][0].data = sitesArr
                // userSitesFormArr[0][1].data = rolesDataArr
                setSitesOptions([...sitesArr])
                setUserSiteForm([...userSitesFormArr])
            })
        } else {
            currentUser.profile.Sites.forEach(site => {
                sitesArr.push({ value: site.SiteID, label: site.SiteName, disabled: true })
            })
            // rolesDataArr = userSitesFormArr[0][1].data?.filter(x=>x.value!=="ProductAdmin" && x.value !== "SystemAdmin")
            userSitesFormArr[0][0].data = sitesArr
            // userSitesFormArr[0][1].data = rolesDataArr
            setSitesOptions([...sitesArr])
            setUserSiteForm([...userSitesFormArr])
        }


        /*        if(currentUser.role[0]!=="SystemAdmin"){
                    userSitesFormArr[0][0]['disabled']=true
                    let _userSites = [...userSites]
                    _userSites[0]['SiteID'] = currentUser.profile.SiteID
                }
                userSitesFormArr[0][0].data = sites
                setSiteForm(userSitesFormArr)
                setFormType(form)
                setEntry(user)*/


    }, [])

    const fetchData = async () => {
        return await SystemAdmin.getAllSitesAdvanced({ filters: [] })
    }

    const validateForm = async (currentEntry) => {
        let value = false
        let errorObj = {}
        await userSchema.validate(currentEntry, { abortEarly: false })
            .catch(async function (err) {
                for (const error of err.inner) {
                    let errorText = error.errors
                    let path = error.path
                    errorObj[path] = errorText[0]
                }
                setError({ ...errorObj })
                setIsError(true)
                value = true
            });
        setError({ ...errorObj })
        return value
    }

    const validateSitesArr = async () => {
        let value = false
        let currentArr = entry['userSites']
        let userSiteErr = currentArr.map(() => { return [] })
        await arrayOfUserSiteSchema.validate(currentArr, { abortEarly: false })
            .catch(async function (err) {
                for (const error of err.inner) {
                    let index = error.path.substring(1, 2)
                    let path = error.path.substring(4)
                    userSiteErr[index][path] = error.errors
                }
                value = true
                setIsError(true)
                setUserSiteErrArr([...userSiteErr])
            });
        setUserSiteErrArr(userSiteErr)

        return value
    }

    const onChange = async (name, value) => {
        let currentEntry = { ...entry }
        if (value === "") {
            value = null
        }
        if (name === "Gender") {
            value = value.value
        }
        currentEntry[name] = value
        setEntry({ ...currentEntry })
        if (error[name]) {
            await validateForm(currentEntry)
        }
    }

    const DeleteOptionSites = async (value) => {
        let sitesArr = [...sitesOptions]
        let userSitesFormArr = [...userSiteForm]
        let index = sitesArr.findIndex(item => item['value'] === value)
        if (index >= 0) {
            sitesArr[index]['isDisabled'] = true
            userSitesFormArr.map(form => {
                form[0].data[index]['isDisabled'] = true
            })
            setSitesOptions([...sitesArr])
            setUserSiteForm([...userSitesFormArr])
        }
    }

    const AddOptionSites = async (value) => {
        let sitesArr = [...sitesOptions]
        let userSitesFormArr = [...userSiteForm]
        let index = sitesArr.findIndex(item => item['value'] === value)
        if (index >= 0) {
            sitesArr[index]['isDisabled'] = false
            userSitesFormArr.map(form => {
                form[0].data[index]['isDisabled'] = false
            })
            setSitesOptions([...sitesArr])
            setUserSiteForm([...userSitesFormArr])
        }
    }

    const onUserSiteChange = async (name, value, index) => {
        let currentEntry = { ...entry }
        if (value === "") {
            value = null
        }
        if (name === "SiteID") {
            value = value.value
            if (currentEntry['userSites'][index][name]) {
                AddOptionSites(currentEntry['userSites'][index][name])
            }
            DeleteOptionSites(value)
        } else if (name === "Role") {
            value = value.value
        }
        currentEntry['userSites'][index][name] = value
        if (index < userSiteErrArr.length && userSiteErrArr[index][name]) {
            await validateSitesArr(entry)
        }
        setEntry({ ...currentEntry })
    }

    const addUserSiteForm = () => {
        let currentEntry = { ...entry }
        let currentForm = [...userSiteForm]
        currentEntry['userSites'].push({ id: uuidv4() + "-" + new Date().getTime(), isActive: true })
        let form = formData['user_sites']
        form[0]['data'] = sitesOptions
        currentForm.push(form)
        setEntry({ ...entry })
        setUserSiteForm([...currentForm])
    }

    const removeUserSiteForm = (id) => {
        let currentEntry = { ...entry }
        let currentForm = [...userSiteForm]
        let siteValue = currentEntry['userSites'][id]['SiteID']
        currentEntry['userSites'].splice(id, 1)
        currentForm.splice(id, 1)
        AddOptionSites(siteValue)
        setUserSiteForm([...currentForm])
        setEntry({ ...currentEntry })
    }

    const createUser = async () => {
        let validateUser = await validateForm(entry)
        if(entry.IsSystemAdmin){
            let user = { ...entry }
            let sAdmin = {};
            Object.keys(user).forEach(key => {
                if(key !== 'userSites')
                    sAdmin[key] = user[key]
            });
            if (!validateUser) {
                let submit = await SystemAdminApi.createUser(sAdmin)
                if (submit && submit.data) {
                    navigate('/users')
                }
            }
        }else{
            let validateSites = await validateSitesArr(entry)
            let user = { ...entry }
            if (!validateSites && !validateUser) {
                let submit = await SystemAdminApi.createUser(user)
                if (submit && submit.data) {
                    navigate('/users')
                }
            }
        }

    }

    const cancelEdit = async () => {
        navigate('/users')
    }

    return (
        <div className="w-full h-full pt-32 ml-24 overflow-auto">
            <div className={'w-11/12'}>
                <div className={'mb-7'}>
                    <TextAlpha text={"Users"}
                        className={"text-alphaTauText text-titleSize cursor-pointer font-bold"}
                        onClick={() => navigate('/users')} />
                    <TextAlpha text={right} />
                    <TextAlpha text={" New User"} className={"text-alphaTauButton text-titleSize"} />
                </div>

                <FormCard
                    cardClass={'pb-[1.875rem]'}
                    cardTitle={"User info"}
                    data={<AlphaForm
                        data={formType}
                        formData={entry}
                        onChange={e => onChange(e.target.name, e.target.value)}
                        containerClassName={"mb-2 mr-12"}
                        labelClassName={Constants.inputLabelClass}
                        inputClassName={Constants.inputClass}
                        isShown={error}
                        isError={isError}
                    />}
                />

                {!entry.IsSystemAdmin && userSiteForm.map((item, index) => {
                    let adminEntry = entry['userSites'][index]
                    console.log("userSiteForm", userSiteForm)
                    return <FormCard
                        id={"UserSite" + index}
                        cardClass={'pb-[1.875rem]'}
                        blockClass={'mt-5'}
                        cardTitle={"Sites"}
                        length={userSiteForm.length}
                        hasDeleteIcon={true}
                        deleteCurrent={() => removeUserSiteForm(index)}
                        data={<>
                            <AlphaForm
                                data={item}
                                index={"-UserSite" + adminEntry.id}
                                formData={adminEntry}
                                country={entry['Country']?.value}
                                onChange={e => onUserSiteChange(e.target.name, e.target.value, index)}
                                containerClassName={"mb-6 mr-12"}
                                labelClassName={Constants.inputLabelClass}
                                inputClassName={Constants.inputClass}
                                isShown={userSiteErrArr[index]}
                                isError={isError}
                            />
                        </>
                        }

                    />
                })}

                {
                    !entry.IsSystemAdmin && (
                        <div
                            className={'text-alphaTauButton text-[1.25rem] h-[3.5rem] font-bold font-Roboto flex items-center cursor-pointer'}
                            onClick={() => addUserSiteForm()}>
                            <SVGraphics className={'w-6 h-6 mr-[0.75rem]'} svgname={'plus-circle'} />
                            Add Site
                        </div>
                    )
                }


                <div className={'float-right my-8 flex items-center pb-[3.125rem]'}>
                    <button type="button"
                        onClick={() => createUser()}
                        className="mr-12 w-loginButton text-white bg-alphaTauButton hover:bg-alphaTauButtonHover font-Roboto font-medium rounded-rounded10 text-textSize px-5 py-2.5 text-center">
                        Create
                    </button>
                    <div
                        className={'mr-10 underline underline-offset-4 text-alphaTauButton cursor-pointer text-[1.313rem]'}
                        onClick={() => cancelEdit()}>Cancel
                    </div>
                </div>
            </div>
        </div>
    )
}