import React, {useEffect, useState} from "react";
import {TextAlpha} from "../input/TextAlpha";
import logo from "../../assets/logo.svg"
import SVGraphics from "../../assets/SVGraphics";
import {useNavigate} from "react-router-dom";
import {authenticationService} from "../../services/AuthinticationService";
import AppLogout from "../view/auth/AppLogout ";
import ChambersApi from "../../services/ApiServices/ChambersApi";
import {useAppSelector} from "../../redux/hooks";
import {SelectAlpha} from "../input/SelectAlpha";
import UsersApi from "../../services/ApiServices/UsersApi";


export function NavBar(props) {
    const {
        logoutUser
    } = props

    const [navType, setNavType] = useState('sites');
    const [currentUser, setCurrentUser] = useState(null);
    const [navOptions, setNavOptions] = useState([]);
    const [userSites, setUserSites] = useState([]);
    const [alerts, setAlerts] = useState(0);
    const [selectedSite, setSelectedSite] = useState("");
    const chambersData = useAppSelector((state)=>state.chambers.chambers)
    const navigate = useNavigate()
    const disabledChangeSite = window.location.pathname.includes('addChamber')|| window.location.pathname.includes('editChamber')||window.location.pathname.includes('sites')||window.location.pathname.includes('addSite') ||window.location.pathname.includes('editSite') || window.location.pathname.includes('configurations')
    ||window.location.pathname.includes('users')|| window.location.pathname.includes('addUser')|| window.location.pathname.includes('editUser')

    useEffect(() => {
        let userSites = []
        let current = authenticationService.currentUser.source._value.profile
        setSelectedSite(current.SiteID)
        if(current.Sites?.length>0){
            current.Sites.forEach(site=>{
                if(site.userSites[0].isActive)
                    userSites.push({label:site.SiteName, value:site.SiteID})
            })
        }
        setUserSites(userSites)

        if(window.location.pathname.includes('sites')||window.location.pathname.includes('addSite')||window.location.pathname.includes('editSite')){
            setNavType('sites')
        }else if(window.location.pathname.includes('configurations')){
            setNavType('configurations')
        }else if(window.location.pathname.includes('chambers') || window.location.pathname.includes('addChamber')|| window.location.pathname.includes('editChamber')){
            setNavType('chambers')  }
        else if(window.location.pathname.includes('orders')|| window.location.pathname.includes('calculateOrder')|| window.location.pathname.includes('orderPlans')){
            setNavType('orders')
        }
        else if(window.location.pathname.includes('gantt')){
            setNavType('gantt')
        }  else if(window.location.pathname.includes('users')|| window.location.pathname.includes('addUser')|| window.location.pathname.includes('editUser')){
            setNavType('users')
        }else{
            setNavType('sites')
        }
        authenticationService.currentUser.subscribe(
            currentUser => setCurrentUser(currentUser)
        );
        const getChambers = async () => {
            let data = await ChambersApi.getAllChambers({filters: []})
            let chambersAlerts = 0
            if(data && data.data){
                data.data.forEach(d=>{
                    if(d.needToUpdateActivity){
                        chambersAlerts = chambersAlerts+1
                    }
                })
                setAlerts(chambersAlerts)
            }
        }
        let userRole = authenticationService.currentUser.source._value.role[0]
        if(userRole==="SystemAdmin"||userRole==="ProductAdmin"||userRole==="TeamLeader"){
            getChambers().then()
        }
        if(userRole==="SystemAdmin"){
           setNavOptions([
               {name: 'gantt', label: 'Gantt'},
               {name: 'sites', label: 'Sites'},
               {name: 'chambers', label: 'Chambers'},
               {name: 'orders', label: 'Orders'},
               {name: 'users', label: 'Users'},
               {name: 'configurations', label: 'Configurations'},
           ])
        }else if(userRole==="ProductAdmin"){
            setNavOptions([
                {name: 'gantt', label: 'Gantt'},
                {name: 'chambers', label: 'Chambers'},
                {name: 'orders', label: 'Orders'},
                {name: 'users', label: 'Users'},
                {name: 'configurations', label: 'Configurations'},
            ])
        }else if(userRole==="TeamLeader" || userRole==="Engineer" || userRole==="User"|| userRole==="Viewer"){
            setNavOptions([
                {name: 'gantt', label: 'Gantt'},
                {name: 'chambers', label: 'Chambers'},
                {name: 'orders', label: 'Orders'},

            ])
        }
    }, [currentUser])

    useEffect(()=>{
        let chambersAlerts = 0
        chambersData?.forEach(d=>{
            if(d.needToUpdateActivity){
                chambersAlerts = chambersAlerts+1
            }
        })
        setAlerts(chambersAlerts)
    },[chambersData])


    const setType = (type) => {
        switch (type) {
            case 'gantt':
                setNavType(type)
                navigate(`/gantt`)
                break;
            case 'sites':
                setNavType(type)
                navigate(`/systemAdmin/sites`)
                break;
            case 'chambers':
                setNavType(type)
                navigate(`/chambers`)
                break;
            case 'orders':
                setNavType(type)
                navigate(`/orders`)
                break;
            case 'users':
                setNavType(type)
                navigate(`/users`)
                break;
            case 'configurations':
                setNavType(type)
                navigate(`/configurations`)
                break;
            default:
                setNavType(type)
                navigate(`/systemAdmin/sites`)
                break;
        }
    }

    const onChange = async (value) => {

        setSelectedSite(value.value)
        let changeSite = await UsersApi.changeSite(currentUser.profile.UserStorageId,value.value)
        authenticationService.setUser(changeSite.data)
        setCurrentUser(changeSite.data)
        await props.changeSite(value)

    }

    return (
        <AppLogout>
            <div className="text-4xl font-medium text-gray-500 border-b border-gray-200 h-height6rem max-h-height6rem bg-white absolute w-full z-50">
                <nav className={` border-gray-200  h-full max-h-height6rem w-5/5`}>
                        <div className={'flex items-center h-full w-full justify-between'}>

                        <img src={logo} alt={'Alpha'} className={"w-logoWidth h-logoHeight"}/>
                            <div className={'w-4/5 flex items-center justify-between'}>
                        <TextAlpha text={"Welcome, "+currentUser?.profile.FirstName + " "+currentUser?.profile.LastName} className={`text-xl font-bold text-alphaTauText`} />

                            {currentUser && currentUser.role[0] !== "SystemAdmin" &&
                                <SelectAlpha
                                    labelClassName={"font-Roboto text-inputSize text-alphaTauText font-bold mb-2"}
                                    inputClassName={"select-repeat w-[17.75rem]  rounded-xl h-14  font-Roboto text-alphaTauText "}
                                    inputBg={'#F2F5FA'}
                                    placeholder={"Select Site"}
                                    containerClassName={" mr-[5.2rem]"}
                                    name={'SiteID'}
                                    onChange={val =>onChange(val)}
                                    data={userSites}
                                    isIcon={true}
                                    svgName={'sitePlace'}
                                    value={selectedSite}
                                    disabled={disabledChangeSite}
                                />
                            }
                            </div>
                        </div>


                </nav>
            </div>

                <div className={'bg-alphaTauButton w-navWidth h-full flex flex-col justify-between pt-32'}>
                    <div className="hidden md:block md:w-auto  px-4" id="navbar-default">
                        <ul className="flex flex-col pt-1">
                            {navOptions.map(type => {

                                return <li
                                    key={type.name}
                                    className={`mr-12 h-12 mt-7`}
                                    onClick={() => setType(type.name)}>
                                    <div className={`flex items-center text-2xl  w-width218px h-14 ${navType === type.name ? "text-selectedText bg-navSelected rounded-navBorder " : "text-navText"}`}>
                                        <SVGraphics className="mr-3 h-6 w-6 ml-4" svgname={type.name} fill={`${navType === type.name ?'#F2F5FA':'#A8B1B4'}`} />
                                        <TextAlpha text={type.label} className="cursor-pointer  text-navColor "/>
                                        {type.name==='chambers' && alerts>0 &&
                                        <div className={'w-[1.5rem] h-[1.5rem] bg-alphaTauButtonSecondary text-[#FFFFFF] rounded-full flex justify-center items-center text-[1.125rem] ml-[0.75rem]' }>{alerts}</div>
                                        }
                                    </div>

                                </li>
                            })}
                        </ul>

                    </div>
                    <div className={'flex items-center ml-4 mb-6'} onClick={() => logoutUser()}>
                        <SVGraphics className="mr-3 h-6 w-6 ml-4" svgname={'logout'} />
                        <TextAlpha text={'Logout'} className="cursor-pointer  text-selectedText text-2xl" />
                    </div>

            </div>

        </AppLogout>
    )
}
