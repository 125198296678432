const CustomToolbar = (toolbar) => {

    const legend= [{label:'Schedule',color:'#17597C'},{label:'Blocked',color:'black'}]
    const goToBack = () => {
        toolbar.onNavigate('PREV');
    };

    const goToNext = () => {
        toolbar.onNavigate('NEXT');
    };

    // const goToToday = () => {
    //     toolbar.onNavigate('TODAY');
    // };

    // const goToWeek = () => {
    //     toolbar.onView('week');
    // };

    // const goToMonth = () => {
    //     toolbar.onView('month');
    // };

    return (
        <div className="rbc-toolbar justify-end	font-Roboto">
      <span className="rbc-btn-group mr-[16%]" >
        <button type="button" className={'border-0'} onClick={goToBack}>
          {'<'}
        </button>
                <span className="rbc-toolbar-label text-[1.5rem]">{toolbar.label}</span>
        <button type="button" className={'border-0'} onClick={goToNext}>
             {'>'}
        </button>

      </span>
        <div className={'flex '}>
            {legend.map(item=>{
                return <div key={item.label} className={'flex ml-[1.5rem] items-center'}>
                    <div className={`w-[3.125rem] h-[0.625rem] rounded-[0.313rem] `} style={{backgroundColor:item.color}}></div>
                    <div className={' ml-[1rem] text-[1.125rem]'}>{item.label}</div>
                </div>
            })}

        </div>
        </div>
    );
};

export default CustomToolbar