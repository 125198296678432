import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import formData from "../../../forms/formsData.json";
import { v4 as uuidv4 } from "uuid";
import { array, object, string } from "yup";
import { TextAlpha } from "../../../input/TextAlpha";
import { FormCard } from "../../../forms/FormCard";
import { AlphaForm } from "../../../forms/AlphaForm";
import SVGraphics from "../../../../assets/SVGraphics";
import Constants from "../../../../utils/constants";
import { authenticationService } from "../../../../services/AuthinticationService";
import SystemAdmin from "../../../../services/ApiServices/SystemAdminApi";
import { SystemAdminApi } from "../../../../services/ApiService";
import LoginAPICalls from "../../../../services/ApiServices/LoginAPICalls";
import { AlertConfirm } from "../../../modals/Confirm";


export function EditUser() {
    const right = <span className={"text-[#444444] text-2xl text-titleSize ml-2"}>&#8250;</span>
    const navigate = useNavigate();
    const [entry, setEntry] = useState({ userSites: [{ id: uuidv4() + "-" + new Date().getTime() }] });
    const [userSiteForm, setUserSiteForm] = useState([formData['edit_user_sites']])
    const [userSiteErrArr, setUserSiteErrArr] = useState([])
    const [error, setError] = useState({})
    const [isError, setIsError] = useState(false)
    const [sitesOptions, setSitesOptions] = useState([])
    const formType = formData['edit_user']
    const currentUser = authenticationService.currentUserValue
    const userRole = currentUser ? currentUser['role'][0] : null
    const location = useLocation();

    const userSchema = object().shape({
        FirstName: string().required('Please insert First Name'),
        LastName: string().required('Please insert Last Name'),
        Gender: string().required('Please insert Gender'),
        PhoneNumber: string().nullable(),
        Email: string().matches(Constants.emailRegExp, "Invalid Email").required("Please insert Email"),
    });

    const userSiteSchema = object().shape({
        SiteID: string().required('Please select Site'),
        Role: string().required('Please select Role'),
    })

    const arrayOfUserSiteSchema =
        array().of(userSiteSchema)

    useEffect(() => {
        let currentUser = authenticationService.currentUserValue
        let userSitesFormArr = []
        let sitesArr = []
        let user = location.state;
        let currentUserSites = user['userSites']
        let currentSitesArr = currentUserSites.map((site, index) => {
            userSitesFormArr.push(formData['edit_user_sites'])
            currentUserSites[index]['Role'] = site['Role'][0]
            return site['SiteID']
        })
        user['userSites'] = currentUserSites
        console.log(user, "user");
        console.log(currentUserSites);
        // let rolesDataArr = userSitesFormArr[0][1].data?.filter(x=> x.value !== "SystemAdmin")
        if (userRole === "SystemAdmin") {
            fetchData().then(p => {
                p.data.forEach(site => {
                    let isDisabled = currentSitesArr.indexOf(site['SiteID']) >= 0
                    if (site['IsActive']) {
                        sitesArr.push({ value: site.SiteID, label: site.SiteName, isDisabled: isDisabled })
                    }
                })
                userSitesFormArr[0][0].data = sitesArr
                // userSitesFormArr[0][1].data = rolesDataArr
                setSitesOptions([...sitesArr])
                setUserSiteForm([...userSitesFormArr])
                setEntry({ ...user })
            })
        } else {
            currentUser.profile.Sites.forEach(site => {
                let isDisabled = currentSitesArr.indexOf(site['SiteID']) >= 0
                sitesArr.push({ value: site.SiteID, label: site.SiteName, isDisabled: isDisabled })
            })
            // rolesDataArr = userSitesFormArr[0][1].data?.filter(x=>x.value!=="ProductAdmin" && x.value !== "SystemAdmin")
            userSitesFormArr[0][0].data = sitesArr
            // userSitesFormArr[0][1].data = rolesDataArr
            setSitesOptions([...sitesArr])
            setUserSiteForm([...userSitesFormArr])
            setEntry({ ...user })
        }

    }, [])

    const fetchData = async () => {
        return await SystemAdmin.getAllSitesAdvanced({ filters: [] })
    }

    const validateForm = async (currentEntry) => {
        let value = false
        let errorObj = {}
        await userSchema.validate(currentEntry, { abortEarly: false })
            .catch(async function (err) {
                for (const error of err.inner) {
                    let errorText = error.errors
                    let path = error.path
                    errorObj[path] = errorText[0]
                }
                setError({ ...errorObj })
                setIsError(true)
                value = true
            });
        setError({ ...errorObj })
        return value
    }

    const validateSitesArr = async () => {
        let value = false
        let currentArr = entry['userSites']
        let userSiteErr = currentArr.map(() => { return [] })
        await arrayOfUserSiteSchema.validate(currentArr, { abortEarly: false })
            .catch(async function (err) {
                for (const error of err.inner) {
                    let index = error.path.substring(1, 2)
                    let path = error.path.substring(4)
                    userSiteErr[index][path] = error.errors
                }
                value = true
                setIsError(true)
                setUserSiteErrArr([...userSiteErr])
            });
        setUserSiteErrArr(userSiteErr)

        return value
    }

    const onChange = async (name, value) => {
        let currentEntry = { ...entry }
        if (value === "") {
            value = null
        }
        if (name === "Gender") {
            value = value.value
        }
        currentEntry[name] = value
        setEntry({ ...currentEntry })
        if (error[name]) {
            await validateForm(currentEntry)
        }
    }

    const DeleteOptionSites = async (value) => {
        let sitesArr = [...sitesOptions]
        let userSitesFormArr = [...userSiteForm]
        let index = sitesArr.findIndex(item => item['value'] === value)
        if (index >= 0) {
            sitesArr[index]['isDisabled'] = true
            userSitesFormArr.map(form => {
                form[0].data[index]['isDisabled'] = true
            })
            setSitesOptions([...sitesArr])
            setUserSiteForm([...userSitesFormArr])
        }
    }

    const AddOptionSites = async (value) => {
        let sitesArr = [...sitesOptions]
        let userSitesFormArr = [...userSiteForm]
        let index = sitesArr.findIndex(item => item['value'] === value)
        if (index >= 0) {
            sitesArr[index]['isDisabled'] = false
            userSitesFormArr.map(form => {
                form[0].data[index]['isDisabled'] = false
            })
            setSitesOptions([...sitesArr])
            setUserSiteForm([...userSitesFormArr])
        }
    }

    const onUserSiteChange = async (name, value, index) => {
        let currentEntry = { ...entry }
        if (value === "") {
            value = null
        }
        if (name === "SiteID") {
            value = value.value
            if (currentEntry['userSites'][index][name]) {
                AddOptionSites(currentEntry['userSites'][index][name])
            }
            DeleteOptionSites(value)
        } else if (name === "Role") {
            value = value.value
        }
        currentEntry['userSites'][index][name] = value
        if (index < userSiteErrArr.length && userSiteErrArr[index][name]) {
            await validateSitesArr(entry)
        }
        setEntry({ ...currentEntry })
    }

    const modifyUser = async () => {
        let user = { ...entry }
        let valueValidateForm = await validateForm(user)
        let valueValidateSites = await validateSitesArr(user['userSites'])

        user['userSites'].forEach(userSite => {
            userSite['isActive'] = !!userSite['isActive'] || !!userSite['isActive'] === 1
        })
        console.log("user", user)
        if (!valueValidateForm && !valueValidateSites) {
            let submit = await SystemAdminApi.editUser(user)
            if (submit && submit.data) {
                navigate('/users')
            }
        }

    }

    const removeUserSiteForm = (id) => {
        let currentEntry = { ...entry }
        let currentForm = [...userSiteForm]
        let siteValue = currentEntry['userSites'][id]['SiteID']
        currentEntry['userSites'].splice(id, 1)
        currentForm.splice(id, 1)
        AddOptionSites(siteValue)
        setUserSiteForm([...currentForm])
        setEntry({ ...currentEntry })
    }


    const addUserSiteForm = () => {
        let currentEntry = { ...entry }
        let currentForm = [...userSiteForm]
        currentEntry['userSites'].push({ id: uuidv4() + "-" + new Date().getTime(), isActive: true })
        let form = formData['edit_user_sites']
        form[0]['data'] = sitesOptions
        currentForm.push(form)
        setEntry({ ...entry })
        setUserSiteForm([...currentForm])
    }


    const resetUserPassword = async () => {
        if (entry.Email) {
            let confirmReset = await AlertConfirm({
                proceedLabel: 'Yes',
                cancelLabel: 'No'
            }, "Are you sure you want to Reset user password?", "User reset password")
            if (confirmReset) {
                let response = await LoginAPICalls.forgetPass({ Email: entry.Email })
                if (response) {
                    await AlertConfirm({
                        proceedLabel: 'Ok',
                        cancelLabel: 'none'
                    }, "Reset done successfully", "User reset password")

                }
            }

        }
    }



    return (
        <div className="w-full h-full pt-32 ml-24 overflow-auto">
            <div className={'w-11/12'}>
                <div className={'mb-7'}>
                    <TextAlpha text={"Users"} className={"text-alphaTauText text-[1.688rem] text-titleSize cursor-pointer font-bold"} onClick={() => navigate('/users')} />
                    <TextAlpha text={right} />
                    <TextAlpha text={" Edit user"} className={"text-alphaTauButton text-[1.5rem] text-titleSize"} />

                </div>

                <FormCard
                    cardClass={'pb-[1.875rem]'}
                    cardTitle={"User info"}
                    hasAdditional={true}
                    additionalComp={
                        <div
                            className={"cursor-pointer w-[9.375rem] h-[1.563rem] text-[1.313rem] leading-[1.563rem] font-Roboto font-normal text-alphaTauButton border-b border-alphaTauButton"}
                            onClick={() => resetUserPassword()}>Reset password</div>
                    }
                    data={<AlphaForm
                        data={formType}
                        formData={entry}
                        onChange={e => onChange(e.target.name, e.target.value)}
                        containerClassName={"mb-2 mr-12"}
                        labelClassName={Constants.inputLabelClass}
                        inputClassName={Constants.inputClass}
                        isShown={error}
                        isError={isError}
                    />}
                />

                {userSiteForm.map((item, index) => {
                    let adminEntry = entry['userSites'][index]
                    return <FormCard
                        id={"UserSite" + index}
                        cardClass={'pb-[1.875rem]'}
                        blockClass={'mt-5'}
                        cardTitle={"Sites"}
                        length={userSiteForm.length}
                        hasDeleteIcon={true}
                        deleteCurrent={() => removeUserSiteForm(index)}
                        data={<>
                            <AlphaForm
                                data={item}
                                index={"-UserSite" + adminEntry.id}
                                formData={adminEntry}
                                country={entry['Country']?.value}
                                onChange={e => onUserSiteChange(e.target.name, e.target.value, index)}
                                containerClassName={"mb-6 mr-12"}
                                labelClassName={Constants.inputLabelClass}
                                inputClassName={Constants.inputClass}
                                isShown={userSiteErrArr[index]}
                                isError={isError}
                            />
                        </>
                        }

                    />
                })}


                {userRole && userRole === "SystemAdmin" &&
                    <div
                        className={'text-alphaTauButton text-[1.25rem] h-[3.5rem] w-[8rem] font-bold font-Roboto flex items-center cursor-pointer'}
                        onClick={() => addUserSiteForm()}>
                        <SVGraphics className={'w-6 h-6 mr-[0.75rem]'} svgname={'plus-circle'} />
                        Add Site
                    </div>
                }

                <div className={'float-right my-8 flex items-center pb-[3.125rem]'}>
                    <button type="button"
                        disabled={false}
                        onClick={() => modifyUser()}
                        className="mr-6 w-loginButton text-white bg-alphaTauButton hover:bg-alphaTauButtonHover font-Roboto font-medium rounded-rounded10 text-textSize px-5 py-2.5 text-center" >
                        Update
                    </button>
                    <div className={'ml-10 underline underline-offset-4 text-alphaTauButton text-[1.313rem] cursor-pointer'} onClick={() => navigate('/users')}>Cancel</div>
                </div>
            </div>
        </div>
    )
}