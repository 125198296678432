import React, { useEffect, useState } from "react";
import { FormCard } from "../../forms/FormCard";
import formData from "../../forms/formsData.json";
import { AlphaForm } from "../../forms/AlphaForm";
import { TextAlpha } from "../../input/TextAlpha";
import { useNavigate, useParams } from "react-router-dom";
import { object, string, number, array, date } from "yup";
import SystemAdmin from "../../../services/ApiServices/SystemAdminApi";
import Constants from "../../../utils/constants";
import ChambersApi from "../../../services/ApiServices/ChambersApi";
import { AlphaBigCalendar } from "../../input/BigCalendar";
import { ScriptForm } from "./ScriptForm";
import { AsymptoticActivityTable } from "./AsymptoticActivityTable";
import moment from "moment";
import AsymptoticActivityCalculation from "../../modals/AsymptoticActivityCalculation";
import SVGraphics from "../../../assets/SVGraphics";
import { AlertConfirm } from "../../modals/Confirm";
import { authenticationService } from "../../../services/AuthinticationService";


export function EditChamber(props) {
    const navigate = useNavigate();
    const [formType, setFormType] = useState(formData['edit_chamber_maintenance'])
    const [entry, setEntry] = useState({});
    const [error, setError] = useState({})
    const [isError, setIsError] = useState(false)
    const [openBlock, setOpenBlock] = useState(false)
    const [openCalculate, setOpenCalculate] = useState(false)
    const [disabled, setDisabled] = useState(false)
    const [events, setEvents] = useState([])
    const [selectedDay, setSelectedDay] = useState('')
    const [charges, setCharges] = useState([])
    const [chargeForm, setChargeForm] = useState([])
    const [isAsymptoticActivity, setIsAsymptoticActivity] = useState(false)
    const [chargeFormErrors, setChargeFormErrors] = useState([{}])
    const [chosenCharges, setChosenCharges] = useState([]);

    let params = useParams();
    const right = <span className={"text-[#444444] text-2xl text-titleSize ml-2"}>&#8250;</span>

    const chamberSchema = object().shape({
        SiteID: string().required('Please insert Site ID'),
        Status: string().required('Please insert Status'),
        ChamberID: string().required('Please insert Chamber ID').default(""),
        GloveBoxNumber: number().typeError('Please insert Number').default(null).nullable(),
        repeat: string().required('Please insert repeat'),
        whenToRepeat: entry['repeat'] === 'daily' ? array().required('Please insert when To Repeat') : string().required('Please insert when To Repeat'),
        scriptStartTime: string().required('Please insert script Start Time'),
    });
    const chargeSchema = object().shape({
        startTime: date().required('Please set start time'),
        endTime: date().required('Please set end time'),
    })
    useEffect(() => {
        let id = params.id
        if (id) {
            getData(id).then()
        }
    }, [params.id])


    const fetchBlockData = async (chamberID) => {
        let data = await ChambersApi.getChamberCalendarActivity(chamberID)
        if (data && data.data) {
            data.data?.blockedChambers?.forEach(d => {
                d['type'] = 'block'
                d['start'] = new Date(d.startDate)
                d['end'] = new Date(d.endDate)
            })
            data.data.SubPlans.forEach(d => {
                d['type'] = 'order'
                d['start'] = new Date(d.startDate)
                d['end'] = new Date(d.endDate)
            })
            let calendarData = data.data.blockedChambers.concat(data.data.SubPlans)
            setEvents(calendarData)
        }

    }
    const validateChargeForm = async (userSites) => {
        let value = false
        for (let i = 0; i < userSites.length; i++) {
            let item = userSites[i]
            let index = i
            let form = [...chargeForm[index]]
            let formErrors = { ...chargeFormErrors[index] }
            await chargeSchema.validate(item, { abortEarly: false })
                .then(function (value) {
                    formErrors = {}
                    chargeForm[index] = [...form]
                    chargeFormErrors[index] = { ...formErrors }
                    setChargeForm([...chargeForm])
                    setChargeFormErrors([...chargeFormErrors])
                })
                .catch(function (err) {
                    value = true
                    formErrors = {}
                    err.inner.forEach(error => {
                        formErrors[error.path] = true
                        let findIndex = form.findIndex(x => x.name === error.path)
                        form[findIndex]['error'] = error.errors
                        setIsError(true)
                    })
                });
            chargeForm[index] = [...form]
            chargeFormErrors[index] = { ...formErrors }
            setChargeForm([...chargeForm])
            setChargeFormErrors([...chargeFormErrors])
        }
        return value
    }

    const getData = async (id) => {
        let form = JSON.parse(JSON.stringify(formType))
        let user = authenticationService.currentUserValue
        let sites = []
        let siteIndex = form.findIndex(x => x.name === 'SiteID')
        if (user?.role[0] === "SystemAdmin") {
            fetchData().then(p => {
                if (p.data) {
                    p.data.forEach(site => {
                        sites.push({ value: site.SiteID, label: site.SiteName })
                    })
                }
                form[siteIndex]['data'] = sites
            })
        }
        let statusOptions = []
        let chamber = await ChambersApi.getChamber(id)
        if (chamber && chamber.data) {
            chamber = chamber.data
            if (chamber.ActivityDate) {
                chamber['ActivityDate'] = moment(chamber['ActivityDate']).format('DD/MM/YYYY')
            }
            form = formData[`edit_chamber_${chamber.Status === 'maintenanceWithOrder' ? 'maintenance' : chamber.Status}`]
            if (chamber.Status === 'maintenance' || chamber.Status === 'Disabled') {
                statusOptions = [{ label: 'Maintenance', value: 'maintenance' },
                { label: 'Disable', value: 'Disabled' }]
            } else if (chamber.Status === 'maintenanceWithOrder') {
                statusOptions = [{ label: 'maintenanceWithOrder', value: 'maintenanceWithOrder' },
                { label: 'Disable', value: 'Disabled' }]
            } else if (chamber.Status === 'active') {
                statusOptions = [{ label: 'Maintenance', value: 'maintenance' },
                { label: 'Active', value: 'active' },
                { label: 'Disable', value: 'Disabled' }]
            }
            // setStatus_options(statusOptions)
            if (chamber.Status === 'active') {
                if (chamber['repeat'] && chamber['repeat'] === 'daily') {
                    chamber['whenToRepeat'] = chamber['whenToRepeat']?.split(',')
                }
                if (!chamber['repeat'] && !chamber['whenToRepeat']) {
                    chamber['repeat'] = 'monthly'
                    chamber['whenToRepeat'] = '1'
                    chamber['scriptStartTime'] = '1 AM'
                }
                await fetchBlockData(chamber.chamberInternalID).then()
            } else if (chamber.Status === 'maintenance') {
                let getCharges = await ChambersApi.getCharges(chamber.chamberInternalID)
                if (getCharges && getCharges.data && getCharges.data.length > 0) {
                    let chargeFormArr = []
                    let chargesData = [...getCharges.data]
                    let __formData = [...formData['edit_chamber_add_charge']]
                    __formData.forEach(d => {
                        d['disabled'] = true
                    })
                    chargesData.forEach(charge => {
                        charge.startTime = new Date(charge.startTime)
                        chargeFormArr.push(__formData)
                        charge.endTime = new Date(charge.endTime)
                        chargeFormErrors.push({})
                    })
                    setCharges(chargesData)
                    setChargeForm(chargesData.length > 0 ? chargeFormArr : [__formData])
                }
            }


            let statusIndex = form.findIndex(x => x.name === 'Status')
            if (user?.role[0] !== 'SystemAdmin') {
                chamber['SiteID'] = user?.profile.SiteID
                form[0]['disabled'] = true
                let currentSite = user?.profile.Sites.find(x => x.SiteID === user?.profile.SiteID)
                form[0]['data'] = [{ value: currentSite?.SiteID, label: currentSite?.SiteName }]
            }
            if (user?.role[0] !== "SystemAdmin" && user?.role[0] !== "ProductAdmin") {
                form[statusIndex]['disabled'] = true
                form.forEach(f => {
                    f['disabled'] = true
                })
            }
            if (statusIndex > -1) {
                form[statusIndex]['data'] = statusOptions
            }
            if (chamber.needToUpdateActivity && (user?.role[0] === "SystemAdmin" || user?.role[0] === "ProductAdmin")) {
                setOpenCalculate(true)
            }
            await setEntry(chamber)
            setFormType(form)
        }
        await setFormType(form)
    }


    const fetchData = async () => {
        return await SystemAdmin.getAllSitesAdvanced({ filters: [] })
    }
    const editChamber = async () => {
        let hasError = false
        delete entry['DisabledDate']
        delete entry['CurrentStatus']
        delete entry['ActivityDate']
        delete entry['lastCalcTime']
        if (entry['repeat'] && entry['repeat'] === 'daily') {
            entry['whenToRepeat'] = entry['whenToRepeat'].toString()
        }
        for (let i = 0; i < charges.length; i++) {
            let notValid = await validateChargeForm(charges, i)
            if (notValid) {
                hasError = true
            }
        }
        if (!hasError) {
            let submit = await ChambersApi.editChamber(entry, entry.chamberInternalID)
            if (submit && submit.data) {
                if (entry?.Status === 'maintenance' && (entry?.process === "charge" || entry?.process === "calculate")) {
                    let _charges = charges.filter(x => x.isNew)
                    _charges.filter(x => x.isNew)
                    if (_charges.length > 0) {
                        _charges.forEach(charge => {
                            charge.startTime = charge.startTime.toISOString()
                            charge.endTime = charge.endTime.toISOString()
                            charge.chargeTime = String(charge.chargeTime)

                        })

                        let chargesToSave = {
                            siteID: entry.SiteID,
                            OrderID: entry.OrderID,
                            chamberInternalID: entry.chamberInternalID,
                            charges: _charges
                        }
                        await ChambersApi.addCharges(chargesToSave)

                    }
                    navigate('/chambers')
                } else {
                    navigate('/chambers')
                }
            }
        }
    }
    const onChange = async (name, value) => {
        if (props.currentUserRole === 'SystemAdmin' || props.currentUserRole === 'ProductAdmin') {
            let chamber = { ...entry }
            let form = JSON.parse(JSON.stringify(formType))
            let formErrors = { ...error }
            if (name === 'ChamberID') {
                value = value.replace(/[^\d.-]+/g, '')

                let gloveBox = value.substring(0).charAt(0)
                chamber[name] = value
                chamber['GloveBoxNumber'] = Number(gloveBox)
            } else if (name === 'SiteID' || name === 'Status' || name === 'repeat' || name === 'scriptStartTime') {
                value = value.value
                chamber[name] = value
            } else if (name === 'whenToRepeat') {
                let days
                if (chamber['repeat'] === 'daily') {
                    if (!chamber['whenToRepeat']) {
                        days = []
                    } else {
                        days = [...chamber['whenToRepeat']]
                    }
                    let dayIndex = days.findIndex(x => x === value)
                    if (dayIndex > -1) {
                        // let _days = days.splice(dayIndex,1)
                        // console.log('days*******',_days)
                        days.splice(dayIndex, 1)
                        chamber['whenToRepeat'] = days
                    } else {
                        days.push(value)
                        chamber['whenToRepeat'] = days
                    }
                } else {
                    chamber['whenToRepeat'] = value
                }
            } else {
                if (value === "") {
                    value = null
                }
                chamber[name] = value
            }
            await chamberSchema.validate(chamber, { abortEarly: false }).then(function (value) {
                formErrors[name] = false
                setDisabled(false)
                setIsError(false)
            })
                .catch(function (err) {
                    let index = form.findIndex(x => x.name === name)
                    err.inner.forEach(error => {
                        if (error.path === name) {
                            formErrors[error.path] = true
                            form[index]['error'] = error.errors
                            setDisabled(true)
                            setIsError(true)
                        }
                    })
                    let errorIndex = err.inner.findIndex(x => x.path === name)
                    if (errorIndex === -1 && index > -1) {
                        formErrors[name] = false
                        form[index]['error'] = []
                    }
                });
            setEntry(chamber)
            setFormType(form)
            setError(formErrors)
        }

    }
    const cancelEdit = async () => {
        navigate('/chambers')
    }
    const selectDay = (day) => {
        setSelectedDay(day)
    }
    function toISOTimeString(dateString, timeString, amPmMarker) {
        // Create a new Date object from the date string
        const date = new Date(dateString.split("T")[0]);

        // Split time into hours and minutes
        let [hours, minutes] = timeString.split(":").map(Number);

        // Convert 12-hour format to 24-hour format
        if (amPmMarker.toLowerCase() === "pm" && hours < 12) {
            hours += 12;
        }
        if (amPmMarker.toLowerCase() === "am" && hours === 12) {
            hours = 0;
        }

        // Set the hours and minutes to the date object
        date.setHours(hours);
        date.setMinutes(minutes);

        // Return the ISO string representation of the time
        return date.toISOString();
    }

    const addBlock = async (block, type, blockIndex) => {
        if (props.currentUserRole === 'SystemAdmin' || props.currentUserRole === 'ProductAdmin') {
            let _start = toISOTimeString(block.startDate.toISOString(), block.startHour + ':' + block.startMinute, block.startHourType)
            let _end = toISOTimeString(block.endDate.toISOString(), block.endHour + ':' + block.endMinute, block.endHourType)

            // let blockToSave = {
            //     startDate: block.startDate.toISOString(),
            //     endDate: block.endDate.toISOString(),
            //     startTime: block.startHour + ':' + block.startMinute,
            //     endTime: block.endHour + ':' + block.endMinute
            // }
            let blockToSave = {
                startDate: _start,
                endDate: _end
            }
            let blockChamber
            if (type === 'create') {
                blockChamber = await ChambersApi.blockChamber(blockToSave, entry.chamberInternalID)
            } else {
                blockChamber = await ChambersApi.editBlockChamber(blockToSave, blockIndex)
            }

            if (blockChamber) {
                closeBlockModal()
            }

            fetchBlockData(entry.chamberInternalID).then()
            // let _block = {blockedTime:[block]
            // }
            // await ChambersApi.blockChamber(_block)
        }
    }
    const closeBlockModal = () => {
        setOpenBlock(false)
    }
    const calculate = () => {
        getData(entry.chamberInternalID).then()
        setIsAsymptoticActivity(false)
    }
    const saveActivityLevel = () => {
        getData(entry.chamberInternalID).then()
        setOpenCalculate(false)
        setIsAsymptoticActivity(false)
    }
    const deleteCurrent = async (index) => {
        const chargesArray = [...charges];
        const chargeFormsArr = [...chargeForm];
        const chargeFormsErrArr = [...chargeFormErrors];
        let chargeToRemove = chargesArray[index]

        let message = <div>Are you sure you want to delete this charge?</div>
        let alert = await AlertConfirm({
            proceedLabel: 'Yes',
            cancelLabel: 'No', options: { hasSVG: true, svgName: "alert-triangle" }
        }, message, '')
        if (alert) {
            if (!chargeToRemove["isNew"]) {
                await ChambersApi.deleteCharge(chargeToRemove)
            }
            if (chargesArray.length > 0) {
                chargesArray.splice(index, 1);
                if (charges.length > 0) {
                    chargeFormsArr.splice(index, 1);
                }
                chargeFormsErrArr.splice(index, 1);
            }
            setChargeFormErrors(chargeFormsErrArr);
            setCharges(chargesArray);
            setChargeForm(chargeFormsArr);

        }
    }
    const returnChargingTime = (start, end) => {
        let date1 = new Date(start);
        let date2 = new Date(end);
        let timeDiff = Math.abs(date2.getTime() - date1.getTime());
        return Math.floor(timeDiff / (1000 * 60 * 60));
    }

    const onChangeChargeForm = async (index, name, value) => {
        let currentFrom = { ...charges[index] }
        let _chargeForm = [...chargeForm]
        if (name === 'startTime') {
            _chargeForm[index][1].minDate = value
            if (charges[index].endTime) {
                currentFrom.chargeTime = returnChargingTime(value, currentFrom.endTime)
                currentFrom.status = currentFrom.status ? currentFrom.status : 'Not Started'
            }

        } else if (name === 'endTime') {
            if (currentFrom.startTime) {
                currentFrom.chargeTime = returnChargingTime(value, currentFrom.startTime)
                currentFrom.status = currentFrom.status ? currentFrom.status : 'Not Started'
            }
        }
        if (value === "") {
            value = null
        }
        currentFrom[name] = value
        charges[index] = { ...currentFrom }
        setCharges([...charges])
        setChosenCharges([...chosenCharges, value])
        setChargeForm(_chargeForm)
        if (chargeFormErrors[index][name]) {
            await validateChargeForm(charges, index)
        }

    }
    const addCharges = async () => {
        let _form = JSON.parse(JSON.stringify(formData['edit_chamber_add_charge']))
        _form.forEach(f => {
            f['disabled'] = false
        })
        if (charges.length !== 0 || chargeForm.length === 0) {
            setChargeForm([...chargeForm, _form]);
        }
        setCharges([...charges, { isNew: true }]);
        setChargeFormErrors([...chargeFormErrors, {}]);
    }
    useEffect(() => {
        console.log('charges changed')
    }, [charges])
    const displayAddCharge = entry?.Status === 'maintenance' && (entry?.process === "charge" || entry?.process === "calculate") && (props.currentUserRole === 'SystemAdmin' || props.currentUserRole === 'ProductAdmin')
    const displaySave = props.currentUserRole === 'SystemAdmin' || props.currentUserRole === 'ProductAdmin'
    return (
        <div className="w-full h-full pt-32 ml-24 overflow-auto">
            <div className={'w-11/12'}>
                <div className={'mb-7'}>
                    <TextAlpha text={"Chambers"}
                        className={"text-alphaTauText text-[1.688rem] text-titleSize cursor-pointer font-bold"}
                        onClick={() => navigate('/chambers')}
                    />
                    <TextAlpha text={right} />
                    <TextAlpha text={" Edit chamber"} className={"text-alphaTauButton text-[1.5rem] text-titleSize"} />
                </div>
                {!isAsymptoticActivity ?
                    <div>
                        <FormCard
                            cardClass={'pb-[1.25rem] mb-[1.5rem]'}
                            cardTitle={"Chamber Info"}
                            data={<> <AlphaForm
                                data={formType}
                                formData={entry}
                                onChange={e => onChange(e.target.name, e.target.value)}
                                containerClassName={"mb-6 mr-12"}
                                labelClassName={Constants.inputLabelClass}
                                inputClassName={Constants.inputClass}
                                isShown={error}
                                isError={isError}
                            />

                                {/*<div className={'flex items-center '}>*/}
                                {/*    <label>Off</label>*/}
                                {/*<Switch*/}
                                {/*checked={entry?.CurrentStatus}*/}
                                {/*inputProps={{ 'aria-label': 'controlled' }}*/}
                                {/*className={''}*/}
                                {/*onChange={e => onChange('CurrentStatus',e)}*/}
                                {/*/>*/}
                                {/*    <label>On</label>*/}
                                {/*</div>*/}
                            </>
                            }

                        />
                        {charges.map((item, index) => {
                            return <FormCard
                                key={item.Index}
                                cardClass={'pb-[1.875rem] 2xl:grid-cols-4 w-full'}
                                // cardStyle={{gridTemplateColumns:'auto auto auto auto'}}
                                blockClass={'mt-5'}

                                cardTitle={"Charging plans"}
                                hasDeleteIcon={(props.currentUserRole === 'SystemAdmin' || props.currentUserRole === 'ProductAdmin') && (item.status === "Not Started" || !item.status)}
                                length={charges.length + 1}
                                deleteCurrent={() => deleteCurrent(index)}
                                data={<AlphaForm
                                    key={index + "charge"}
                                    chosenOptions={chosenCharges}
                                    data={chargeForm[index]}
                                    isClearable={"false"}
                                    formData={charges[index]}
                                    minDate={new Date()}
                                    onChange={e => onChangeChargeForm(index, e.target.name, e.target.value)}
                                    containerClassName={"mb-2 mr-12"}
                                    labelClassName={"font-Roboto text-inputSize text-alphaTauText font-bold"}
                                    inputClassName={"w-inputWidth bg-white rounded-xl h-14 bg-transparent font-Roboto text-alphaTauText "}
                                    labelInputClass={"font-Roboto text-alphaTauText mt-4 "}
                                    isShown={chargeFormErrors[index]}
                                    isError={isError}
                                />
                                } />
                        })}

                        {displayAddCharge ?
                            <div
                                className={'text-alphaTauButton text-[1.25rem] h-[3.5rem] font-bold font-Roboto flex items-center cursor-pointer max-w-max '}
                                onClick={() => addCharges()}>
                                <SVGraphics className={'w-6 h-6 mr-[0.75rem]'} svgname={'plus-circle'} />
                                Add Charge
                            </div> : null
                        }
                        {entry?.Status === 'active' &&
                            <div>
                                <FormCard
                                    cardClass={'pb-[1.25rem] mb-[1.5rem]'}
                                    cardTitleClass={'w-[100%]'}
                                    cardTitle={<div className={'flex w-12/12 justify-between items-center'}><div>Script Run Time</div>
                                        <div className={`text-alphaTauButton underline text-[1.125rem] mr-[2rem] font-Roboto font-normal cursor-pointer ${props.currentUserRole !== 'SystemAdmin' && props.currentUserRole !== 'ProductAdmin' && 'hidden'}`}
                                            onClick={() => setIsAsymptoticActivity(true)}
                                        >
                                            View chamber measurements
                                        </div></div>}
                                    data={<ScriptForm
                                        isError={isError}
                                        disabledRepeat={props.currentUserRole !== 'SystemAdmin' && props.currentUserRole !== 'ProductAdmin'}
                                        disabledScriptStartTime={props.currentUserRole !== 'SystemAdmin' && props.currentUserRole !== 'ProductAdmin'}
                                        selectDay={selectDay}
                                        selected={selectedDay}
                                        onChange={e => onChange(e.target.name, e.target.value)}
                                        data={entry}
                                        isShown={error}

                                    />}

                                />
                                <div className={'w-full bg-white h-auto rounded-[0.625rem] p-[2.125rem] shadow-lg'}>
                                    <div className={'flex w-12/12 justify-between items-center'}>
                                        <div
                                            className={"pt-[0.938rem] text-alphaTauButton text-2xl font-bold text-titleSize"}>Block
                                            Chamber
                                        </div>
                                        {props.currentUserRole === 'SystemAdmin' && props.currentUserRole === 'ProductAdmin' &&
                                            <button
                                                className={`${Constants.buttonClass} bg-alphaTauButtonSecondary text-white px-4 h-[2rem]`}
                                                onClick={() => setOpenBlock(true)}> Block
                                            </button>
                                        }
                                    </div>
                                    <AlphaBigCalendar
                                        containerClassName={'w-full'}
                                        setBlock={addBlock}
                                        disabled={props.currentUserRole !== 'SystemAdmin' && props.currentUserRole !== 'ProductAdmin'}
                                        openBlock={openBlock}
                                        chamberInternalId={entry?.chamberInternalID}
                                        closeBlockModal={() => closeBlockModal()}
                                        events={events}
                                    />
                                </div>
                            </div>

                        }

                        <div
                            className={`my-8 flex mt-[6rem] w-full lg:flex-row sm:flex-col ${entry?.Status === 'maintenance' && entry?.process === "calculate" ? 'justify-between' : 'justify-end'}`}>
                            {entry?.Status === 'maintenance' && entry?.process === "calculate" &&
                                <button
                                    disabled={props.currentUserRole !== 'SystemAdmin' && props.currentUserRole !== 'ProductAdmin'}
                                    onClick={() => setIsAsymptoticActivity(true)}
                                    className={`${Constants.buttonClass} bg-alphaTauButtonSecondary w-[21.75rem] h-[3.5rem] text-[1.25rem] text-white hover:opacity-75`}>
                                    {` Calculate asymptotic activity`}
                                </button>
                            }
                            <div className={'flex items-center sm:mt-[2rem] md:mt-[0rem]'}>
                                {displaySave &&
                                    <button type="button"
                                        disabled={(props.currentUserRole !== 'SystemAdmin' && props.currentUserRole !== 'ProductAdmin') ? true : disabled}
                                        onClick={() => editChamber()}
                                        className={`${Constants.buttonClass} mr-12 w-loginButton text-white bg-alphaTauButton hover:bg-alphaTauButtonHover font-Roboto font-medium rounded-rounded10 text-textSize px-5 py-2.5 text-center`}>
                                        Apply
                                    </button>
                                }

                                <div className={'underline underline-offset-4 text-alphaTauButton text-[1.313rem]'}
                                    onClick={() => cancelEdit()}>Cancel
                                </div>
                            </div>
                        </div>

                    </div>
                    :
                    <AsymptoticActivityTable
                        chamber={entry}
                        cancelCalculate={() => setIsAsymptoticActivity(false)}
                        calculate={() => calculate()}
                        addNewCharge={() => setIsAsymptoticActivity(false)}
                        saveActivityLevel={() => saveActivityLevel()}
                    />
                }
                {openCalculate &&
                    <AsymptoticActivityCalculation
                        chamber={entry}
                        onCancel={() => setOpenCalculate(false)}
                        saveActivityLevel={() => saveActivityLevel()}

                    />
                }
            </div>

        </div>
    )



}